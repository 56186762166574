/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { openContractDeploy } from "@stacks/connect";
import { userSession } from "components/ConnectWallet";
import React, { useState, useCallback } from "react";
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { FormLabel, FormControlLabel } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-allevents.jpg";

import SimpleFooter from "examples/Footers/SimpleFooter";
import MKButton from "components/MKButton";
import { StacksTestnet } from "@stacks/network";
import { postPendingChainhook } from "stores/ContractsStore";

// Images
import appLogo from "assets/images/all-access/rising.svg";
import { postPendingTicketingAgreement } from "stores/ContractsStore";

import { TwitterShareButton } from "react-share";
import { XIcon } from "react-share";

const startTimeOpt = { timeZoneName: "short", hour: "2-digit", minute: "2-digit" };
const endTimeOpt = { timeZoneName: "short", hour: "2-digit", minute: "2-digit" };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: { xs: 1, sm: 8 / 10, md: 7 / 10, lg: 5 / 10, xl: 4 / 10 },
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateEvent() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const [contractName, setContractName] = useState("");
  const [validated, setValidated] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [errorMsgs, setErrorMsgs] = useState({});
  const [assetId, setAssetId] = useState("");
  const [formData, setFormData] = useState({
    eventName: "",
    eventDescription: "",
    ticketCount: 100,
    ticketPrice: 2,
    thumbnailUri: "",
    email: "",
    phone: "",
    isTransferable: true,
    offersAllowed: false,
    eventType: 8,
    eventLink: "",
    locationName: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    postal: "",
    country: "",
    startTime: new Date().getTime(),
    endTime: new Date().getTime() + 3600000,
    distributions: [
      {
        id: 0,
        name: "All Access Fee",
        address: "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR",
        percentage: 5,
      },
      {
        id: 1,
        name: "",
        address: "",
        percentage: 95,
      },
    ],
  });

  const contractDeployer =
    userSession !== undefined && userSession.isUserSignedIn()
      ? userSession.loadUserData().profile.stxAddress.testnet
      : "";

  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleClosePreview = () => setShowPreview(false);

  const handlePreviewAgreement = (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      setShowPreview(true);
    }
  };

  const handleSendAgreement = () => {
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      fetch("templates/agreement_chainhook_v1.json.txt")
        .then((r) => r.text())
        .then((text) => {
          handleChainhookRead(text);
          fetch("templates/agreement_contract_v1.clar.txt")
            .then((r) => r.text())
            .then((text) => {
              handleContractRead(text);
              setStatusMessage("");
            });
        });
    }
  };

  const handleChainhookRead = (chainhookTemplate) => {
    setStatusMessage("Generating Chainhook");

    chainhookTemplate = chainhookTemplate
      .replace("##_EVENT_NAME_##", formData.eventName)
      .replace("##_EVENT_CONTRACT_NAME_##", contractName)
      .replace("##_EVENT_CONTRACT_NAME_##", contractName)
      .replace("##_EVENT_CONTRACT_DEPLOYER_##", contractDeployer)
      .replace("##_EVENT_CONTRACT_DEPLOYER_##", contractDeployer)
      .replace("##_EVENT_CHAINHOOK_BASE_URL_##", "https://rt-api-dev.vercel.app")
      .replace("##_EVENT_CHAINHOOK_BASE_URL_##", "https://rt-api-dev.vercel.app");

    generateAndSendChainhook(contractDeployer, contractName, formData.eventName, chainhookTemplate);
  };

  const handleContractRead = (contractTemplate) => {
    setStatusMessage("Generating Contract");

    contractTemplate = contractTemplate
      .replace("##_EVENT_NAME_##", formData.eventName)
      .replace("##_EVENT_VERSION_MAJOR_##", formData.versionMajor)
      .replace("##_EVENT_VERSION_MINOR_##", formData.versionMinor)
      .replace("##_EVENT_VERSION_PATCH_##", formData.versionPatch)
      .replace("##_EVENT_DESCRIPTION_##", formData.eventDescription)
      .replace("##_EVENT_CONTRACT_NAME_##", contractName)
      .replace("##_EVENT_ASSET_IDENTIFIER_##", assetId)
      .replace("##_EVENT_NAME_##", formData.eventName)
      .replace(
        "##_EVENT_THUMBNAIL_##",
        formData.thumbnailUri
          ? formData.thumbnailUri
          : "https://testnet.allaccess.rocks/destinations/rising-tide/logo/rising.png"
      )
      .replace("##_EVENT_DESCRIPTION_##", formData.eventDescription)
      .replace("##_EVENT_TYPE_##", "u" + formData.eventType)
      .replace("##_EVENT_ASSET_IDENTIFIER_##", assetId)
      .replace(
        "##_EVENT_LOCATION_NAME_##",
        formData.eventType === 6
          ? formData.locationName
          : formData.eventType === 7
          ? "Online Meeting"
          : "X Spaces"
      )
      .replace("##_EVENT_START_TIME_##", "u" + formData.startTime)
      .replace("##_EVENT_END_TIME_##", "u" + formData.endTime)
      .replace("##_EVENT_ADDRESS_ONE_##", formData.eventType === 6 ? formData.addressOne : "")
      .replace("##_EVENT_ADDRESS_TWO_##", formData.eventType === 6 ? formData.addressTwo : "")
      .replace("##_EVENT_CITY_##", formData.eventType === 6 ? formData.city : "")
      .replace("##_EVENT_STATE_##", formData.eventType === 6 ? formData.state : "")
      .replace("##_EVENT_POSTAL_##", formData.eventType === 6 ? formData.postal : "")
      .replace("##_EVENT_COUNTRY_##", formData.eventType === 6 ? formData.country : "")
      .replace("##_EVENT_PHONE_##", formData.phone)
      .replace("##_EVENT_EMAIL_##", formData.email)
      .replace("##_EVENT_VERSION_MAJOR_##", "u0")
      .replace("##_EVENT_VERSION_MINOR_##", "u0")
      .replace("##_EVENT_VERSION_PATCH_##", "u1")
      .replace("##_EVENT_PRICE_##", "u" + (parseInt(formData.ticketPrice) * 1000000).toString())
      .replace("##_EVENT_MAX_TICKETS_##", "u" + formData.ticketCount)
      .replace("##_EVENT_TRANSFERABLE_##", formData.isTransferable.toString().toLowerCase())
      .replace("##_EVENT_ACCEPT_OFFERS_##", formData.offersAllowed.toString().toLowerCase())
      .replace("##_EVENT_CONTRACT_NAME_##", contractName)
      .replace("##_EVENT_CONTRACT_NAME_##", contractName)
      .replace("##_EVENT_ASSET_IDENTIFIER_##", assetId)
      .replace("##_EVENT_ASSET_IDENTIFIER_##", assetId)
      .replace("##_EVENT_ASSET_IDENTIFIER_##", assetId)
      .replace("##_EVENT_ASSET_IDENTIFIER_##", assetId)
      .replace("##_EVENT_LINK_##", formData.eventLink);

    //merge distribution items
    let creatorInfo = "";
    let distributionInfo = "";

    formData.distributions.map((d) => {
      //creator and distribution templates
      const creatorTemplate = `
        creator-##_EVENT_CREATOR_ID_##: {
          percent-of-100: ##_EVENT_DIST_PERCENT_##,
          name: "##_EVENT_DIST_NAME_##",
          address: ##_EVENT_DIST_ADDRESS_##
        },`;
      const distributionTemplate = `
        (if (not (is-eq tx-sender (get address (get creator-##_EVENT_CREATOR_ID_## creator-info)))) (unwrap-panic (stx-transfer? (* (/ total-fees u100) (get percent-of-100 (get creator-##_EVENT_CREATOR_ID_## creator-info)))
          tx-sender (get address (get creator-##_EVENT_CREATOR_ID_## creator-info)))) true)
        `;

      const newCreator = creatorTemplate
        .replace("##_EVENT_CREATOR_ID_##", formData.distributions.indexOf(d) + 1)
        .replace("##_EVENT_DIST_PERCENT_##", "u" + d.percentage)
        .replace("##_EVENT_DIST_NAME_##", d.name ? d.name : "n/a")
        .replace("##_EVENT_DIST_ADDRESS_##", d.address ? "'" + d.address : '"n/a"');

      const newDistribution = distributionTemplate.replaceAll(
        "##_EVENT_CREATOR_ID_##",
        formData.distributions.indexOf(d) + 1
      );

      creatorInfo = creatorInfo + newCreator;
      distributionInfo = distributionInfo + newDistribution;
    });

    creatorInfo = creatorInfo.substring(1, creatorInfo.length - 1);
    contractTemplate = contractTemplate.replace("##_EVENT_CREATOR_INFO_##", creatorInfo);
    contractTemplate = contractTemplate.replace("##_EVENT_DISTRIBUTION_INFO_##", distributionInfo);
    console.log(contractTemplate);

    generateAndSendContract(
      contractDeployer,
      contractName,
      contractTemplate,
      "All Access",
      appLogo
    );
  };

  const generateAndSendChainhook = useCallback(
    async (contractDeployer, contractName, chainhookName, chainhook) => {
      setStatusMessage("Sending Chainhook");

      postPendingChainhook(
        "f95f0bd797dae6a8decd1f402f795bc2",
        contractDeployer,
        contractName,
        chainhookName,
        chainhook
      );
    },
    []
  );

  const generateAndSendContract = useCallback(
    async (contractDeployer, contractName, template, appName, appIcon) => {
      setStatusMessage("Sending Contract");

      postPendingTicketingAgreement(contractDeployer, contractName);

      openContractDeploy({
        network: new StacksTestnet(),
        contractName: contractName,
        codeBody: template,
        appDetails: {
          name: appName,
          icon: appIcon,
        },
        onFinish: (data) => {
          setValidated(false);
          setConfirmationTx(
            "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
          );
          setShowConfirmation(true);
        },
      });
    },
    []
  );

  const handleNameChange = (name) => {
    const newAssetId = "rtaa-" + name.toLowerCase().replaceAll(" ", "-");

    setAssetId(newAssetId);
  };

  const handleTextChanged = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setValidated(false);
  };

  const handleCheckboxChanged = (event) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
    setValidated(false);
  };

  const handleRadioGroupChanged = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: parseInt(value) });
    setValidated(false);
  };

  const handleStartTimeChanged = (newTime) => {
    setFormData({ ...formData, startTime: new Date(newTime).getTime() });
    setValidated(false);
  };

  const handleEndTimeChanged = (newTime) => {
    setFormData({ ...formData, endTime: new Date(newTime).getTime() });
    setValidated(false);
  };

  const handleAddRevenueSplit = () => {
    const newDistributions = formData.distributions;
    newDistributions.push({
      id:
        formData.distributions.reduce((max, d) => {
          return parseInt(d.id) > parseInt(max.id) ? d : max;
        }, formData.distributions[0]).id + 1,
      name: "",
      address: "",
      percentage: 0,
    });

    setFormData({ ...formData, distributions: newDistributions });
    setValidated(false);
  };

  const handleRemoveRevenueSplit = (distribution) => {
    const newDistributions = formData.distributions.filter((d) => d.id != distribution.id);
    setFormData({ ...formData, distributions: newDistributions });
    setValidated(false);
  };

  const handleUpdateDistribution = (distributionId, name, address, percentage) => {
    const newDistributions = [];

    formData.distributions.map((d) => {
      let distro = undefined;
      if (d.id === distributionId) {
        distro = { id: distributionId, name: name, address: address, percentage: percentage };
      } else {
        distro = d;
      }

      newDistributions.push(distro);
    });

    setFormData({ ...formData, distributions: newDistributions });
    setValidated(false);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;
    setContractName("rtaa-event-" + new Date().getTime());

    if (!formData.eventName.trim()) {
      isValid = false;
      newErrors.eventName = "Event Name Required";
    }
    if (!formData.eventDescription.toString().trim()) {
      isValid = false;
      newErrors.eventDescription = "Event Description Required";
    }
    if (!assetId.toString().trim()) {
      isValid = false;
      newErrors.assetId = "Asset Identifier Required";
    }
    if (formData.ticketCount < 1) {
      isValid = false;
      newErrors.ticketCount = "Ticket Count Must Be Greater Than Zero";
    }
    if (formData.ticketPrice < 1) {
      isValid = false;
      newErrors.ticketPrice = "Ticket Price Must Be Greater Than Zero";
    }

    if (!formData.eventLink.toString().trim()) {
      isValid = false;
      newErrors.eventLink = "Event Link Required";
    }

    if (formData.eventType == 6) {
      if (!formData.locationName.toString().trim()) {
        isValid = false;
        newErrors.locationName = "Location Name Required";
      }
      if (!formData.addressOne.toString().trim()) {
        isValid = false;
        newErrors.addressOne = "Address One Required";
      }
      if (!formData.city.toString().trim()) {
        isValid = false;
        newErrors.city = "City Required";
      }
      if (!formData.state.toString().trim()) {
        isValid = false;
        newErrors.state = "State Required";
      }
      if (!formData.postal.toString().trim()) {
        isValid = false;
        newErrors.postal = "Postal Required";
      }
      if (!formData.country.toString().trim()) {
        isValid = false;
        newErrors.country = "Country Required";
      }
      if (!formData.locationName.toString().trim()) {
        isValid = false;
        newErrors.locationName = "Location Name Required";
      }
    } else if (formData.eventType == 7) {
      if (!formData.eventLink.toString().trim()) {
        isValid = false;
        newErrors.eventLink = "Event Link Required";
      }
    }

    let total = 0;
    formData.distributions.map((d) => {
      if (parseInt(d.percentage) > 0) {
        if (!d.name && parseInt(d.percentage) > 0) {
          isValid = false;
          newErrors["distributionName" + d.id] = "Name Required";
        }
        if (!d.address && parseInt(d.percentage) > 0) {
          isValid = false;
          newErrors["distributionAddress" + d.id] = "Address Required";
        }
      }
      total = total + parseInt(d.percentage);
    });

    if (total != 100) {
      isValid = false;
      newErrors.distributionAmount = "Total Distribution Must Total 100";
    }

    setErrorMsgs(newErrors);
    setValidated(isValid);
    return newErrors;
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Schedule an Event
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Create and schedule a new event to have it added to the All Access upcoming events.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -15,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(100px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          spacing={2}
          mt={{ xs: 4, sm: 4, md: 2 }}
          mb={{ xs: 10, sm: 10, md: 10 }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid item textAlign="center" xs={12}>
            <MKTypography mb={3} textAlign="center" variant="h2">
              Setup and Schedule Your Event
            </MKTypography>
            <MKTypography mb={1} textAlign="center" variant="body2">
              You are in full control to schedule and setup your event as you see fit. You choose
              how many tickets you&apos;d like to offer, some other basic information, and even how
              you&apos;d like the ticket sale revenue split between wallets. Once complete,
              we&apos;ll create an custom ticketing smart contract, which you will deploy and own
              directly from your wallet. Once confirmed, the event will be listed and you will have
              full control to make updates, it is your event... your smart contract.
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container xs={12}>
              <Grid item p={1} xs={12}>
                <Grid item textAlign="center" p={1} xs={12}>
                  <MKTypography mb={1} variant="h4">
                    Step 1: Enter Event Details
                  </MKTypography>
                  <MKTypography mb={1} variant="body2">
                    Provide the required information to setup your event.
                  </MKTypography>
                </Grid>
                <MKBox pl={3}>
                  <FormLabel>X Spaces Events</FormLabel>
                  <RadioGroup
                    row
                    label="Event Type"
                    value={formData.eventType}
                    onChange={(e) => {
                      handleRadioGroupChanged(e);
                    }}
                    name="eventType"
                  >
                    <FormControlLabel value={8} control={<Radio />} label="Normal" />
                    <FormControlLabel value={9} control={<Radio />} label="Giveaway" />
                    <FormControlLabel value={10} control={<Radio />} label="AMA" />
                  </RadioGroup>
                </MKBox>
                <MKBox pt={2} pl={3}>
                  <FormLabel>General Events</FormLabel>
                  <RadioGroup
                    row
                    label="Event Type"
                    value={formData.eventType}
                    onChange={(e) => {
                      handleRadioGroupChanged(e);
                    }}
                    name="eventType"
                  >
                    <FormControlLabel value={6} control={<Radio />} label="IRL" />
                    <FormControlLabel
                      value={7}
                      control={<Radio />}
                      label="Online (Zoom, Meet, etc)"
                    />
                  </RadioGroup>
                </MKBox>
                <Grid container>
                  {/* Event Info */}
                  <Grid item p={1} xs={12} lg={4}>
                    <MKBox m={2}>
                      <TextField
                        label="Event Name"
                        name="eventName"
                        error={!!errorMsgs.eventName}
                        helperText={errorMsgs.eventName}
                        required
                        value={formData.eventName}
                        onChange={(e) => {
                          handleNameChange(e.target.value);
                          handleTextChanged(e);
                        }}
                        fullWidth
                        type="text"
                        color="primary"
                        size="small"
                      ></TextField>
                    </MKBox>
                    <MKBox m={2}>
                      <TextField
                        label="Event Description"
                        name="eventDescription"
                        error={!!errorMsgs.eventDescription}
                        helperText={errorMsgs.eventDescription}
                        required
                        value={formData.eventDescription}
                        onChange={(e) => {
                          handleTextChanged(e);
                        }}
                        fullWidth
                        multiline
                        rows={4}
                        type="text"
                        color="primary"
                        size="small"
                      ></TextField>
                    </MKBox>
                    <MKBox m={2}>
                      <TextField
                        label="Asset Identifier"
                        name="assetId"
                        required
                        value={assetId}
                        fullWidth
                        type="text"
                        color="primary"
                        size="small"
                        variant="outlined"
                      ></TextField>
                    </MKBox>
                  </Grid>
                  <Grid item p={1} xs={12} lg={4}>
                    <MKBox m={2}>
                      <TextField
                        label="Ticket Count"
                        name="ticketCount"
                        error={!!errorMsgs.ticketCount}
                        helperText={errorMsgs.ticketCount}
                        required
                        value={formData.ticketCount}
                        onChange={(e) => {
                          handleTextChanged(e);
                        }}
                        type="number"
                        color="primary"
                        size="small"
                        variant="outlined"
                      ></TextField>
                    </MKBox>
                    <MKBox m={2}>
                      <TextField
                        label="Ticket Price (STX)"
                        name="ticketPrice"
                        error={!!errorMsgs.ticketPrice}
                        helperText={errorMsgs.ticketPrice}
                        required
                        value={formData.ticketPrice}
                        onChange={(e) => {
                          handleTextChanged(e);
                        }}
                        type="number"
                        color="primary"
                        size="small"
                        variant="outlined"
                      ></TextField>
                    </MKBox>
                    <MKBox m={2}>
                      <TextField
                        label="Thumbnail Uri"
                        name="thumbnailUri"
                        error={!!errorMsgs.thumbnailUri}
                        helperText={errorMsgs.thumbnailUri}
                        value={formData.thumbnailUri}
                        onChange={(e) => {
                          handleTextChanged(e);
                        }}
                        fullWidth
                        type="text"
                        color="primary"
                        size="small"
                        variant="outlined"
                      ></TextField>
                    </MKBox>
                    <MKBox m={2}>
                      <TextField
                        label={
                          formData.eventType === 6
                            ? "Display Name"
                            : formData.eventType === 7
                            ? "Display Name"
                            : "Host X Handle"
                        }
                        name="email"
                        error={!!errorMsgs.email}
                        helperText={errorMsgs.email}
                        value={formData.email}
                        onChange={(e) => {
                          handleTextChanged(e);
                        }}
                        fullWidth
                        type="text"
                        color="primary"
                        size="small"
                        variant="outlined"
                      ></TextField>
                    </MKBox>
                    <MKBox m={2}>
                      <TextField
                        label="Phone"
                        name="phone"
                        error={!!errorMsgs.phone}
                        helperText={errorMsgs.phone}
                        value={formData.phone}
                        onChange={(e) => {
                          handleTextChanged(e);
                        }}
                        type="text"
                        color="primary"
                        size="small"
                        variant="outlined"
                      ></TextField>
                    </MKBox>
                  </Grid>
                  {/* Start/End Time */}
                  <Grid item p={1} xs={12} lg={4}>
                    <MKBox m={2}>
                      <DateTimePicker
                        label="Start Time"
                        name="startTime"
                        disableOpenPicker="true"
                        error={!!errorMsgs.startTime}
                        helperText={errorMsgs.startTime}
                        required
                        value={dayjs(new Date(formData.startTime))}
                        onChange={(newTime) => {
                          handleStartTimeChanged(newTime);
                        }}
                      ></DateTimePicker>
                    </MKBox>
                    <MKBox m={2}>
                      <DateTimePicker
                        label="End Time"
                        name="endTime"
                        disableOpenPicker="true"
                        error={!!errorMsgs.endTime}
                        helperText={errorMsgs.endTime}
                        required
                        defaultValue={dayjs(new Date(formData.endTime))}
                        onChange={(newTime) => {
                          handleEndTimeChanged(newTime);
                        }}
                      ></DateTimePicker>
                    </MKBox>
                    <MKBox m={2}>
                      <FormLabel>Event Options</FormLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isTransferable"
                            checked={formData.isTransferable}
                            onChange={(e) => {
                              handleCheckboxChanged(e);
                            }}
                          />
                        }
                        label="Allow Transfers?"
                      />
                    </MKBox>
                    <MKBox m={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="offersAllowed"
                            checked={formData.offersAllowed}
                            onChange={(e) => {
                              handleCheckboxChanged(e);
                            }}
                          />
                        }
                        label="Allow Offers?"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item mt={3} textAlign="center" xs={12}>
                    <MKTypography mb={1} variant="h4">
                      Step 2: Enter Event Link
                    </MKTypography>
                    <MKTypography mb={2} variant="body2">
                      Provide the link for your event. This link will be included with each ticket.
                      If the event is at a physical location, include a link to where the attendee
                      can find more information.
                    </MKTypography>
                    {/* Online Event */}
                    <MKBox m={3}>
                      <TextField
                        label={
                          formData.eventType === 6
                            ? "Additional Event Information Link"
                            : formData.eventType === 7
                            ? "Meeting Link (Zoom, Meet, Slack, etc)"
                            : "Spaces RSVP Link (ex. https://x.com/i/spaces/1PlJQbEnQZZxE)"
                        }
                        name="eventLink"
                        error={!!errorMsgs.eventLink}
                        helperText={errorMsgs.eventLink}
                        required
                        value={formData.eventLink}
                        onChange={(e) => {
                          handleTextChanged(e);
                        }}
                        fullWidth
                        type="text"
                        color="primary"
                        size="small"
                        variant="outlined"
                      ></TextField>
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>

              {formData.eventType == 6 && (
                <Grid item mt={3} textAlign="center" p={1} xs={12}>
                  <MKTypography mb={1} variant="h4">
                    Step 2.5: Enter Event Location
                  </MKTypography>
                  <MKTypography mb={1} variant="body2">
                    Provide location information for the event.
                  </MKTypography>
                  {/* IRL Event */}
                  <MKBox m={2}>
                    <TextField
                      label="Location Name"
                      name="locationName"
                      error={!!errorMsgs.locationName}
                      helperText={errorMsgs.locationName}
                      required
                      value={formData.locationName}
                      onChange={(e) => {
                        handleTextChanged(e);
                      }}
                      fullWidth
                      type="text"
                      color="primary"
                      size="small"
                      variant="outlined"
                    ></TextField>
                  </MKBox>
                  <MKBox m={2}>
                    <TextField
                      label="Address One"
                      name="addressOne"
                      error={!!errorMsgs.addressOne}
                      helperText={errorMsgs.addressOne}
                      required
                      value={formData.addressOne}
                      onChange={(e) => {
                        handleTextChanged(e);
                      }}
                      fullWidth
                      type="text"
                      color="primary"
                      size="small"
                      variant="outlined"
                    ></TextField>
                  </MKBox>
                  <MKBox m={2}>
                    <TextField
                      label="Address Two"
                      name="addressTwo"
                      value={formData.addressTwo}
                      onChange={(e) => handleTextChanged(e)}
                      fullWidth
                      type="text"
                      color="primary"
                      size="small"
                      variant="outlined"
                    ></TextField>
                  </MKBox>
                  <MKBox m={2}>
                    <TextField
                      label="City"
                      name="city"
                      error={!!errorMsgs.city}
                      helperText={errorMsgs.city}
                      required
                      value={formData.city}
                      onChange={(e) => handleTextChanged(e)}
                      fullWidth
                      type="text"
                      color="primary"
                      size="small"
                      variant="outlined"
                    ></TextField>
                  </MKBox>
                  <MKBox m={2}>
                    <TextField
                      label="State"
                      name="state"
                      error={!!errorMsgs.state}
                      helperText={errorMsgs.state}
                      required
                      value={formData.state}
                      onChange={(e) => handleTextChanged(e)}
                      fullWidth
                      type="text"
                      color="primary"
                      size="small"
                      variant="outlined"
                    ></TextField>
                  </MKBox>
                  <MKBox m={2}>
                    <TextField
                      label="Postal Code"
                      name="postal"
                      error={!!errorMsgs.postal}
                      helperText={errorMsgs.postal}
                      required
                      value={formData.postal}
                      onChange={(e) => {
                        handleTextChanged(e);
                      }}
                      fullWidth
                      type="text"
                      color="primary"
                      size="small"
                      variant="outlined"
                    ></TextField>
                  </MKBox>
                  <MKBox m={2}>
                    <TextField
                      label="Country"
                      name="country"
                      error={!!errorMsgs.country}
                      helperText={errorMsgs.country}
                      required
                      value={formData.country}
                      onChange={(e) => handleTextChanged(e)}
                      fullWidth
                      type="text"
                      color="primary"
                      size="small"
                      variant="outlined"
                    ></TextField>
                  </MKBox>
                </Grid>
              )}
            </Grid>

            <Grid item mt={3} textAlign="center" p={1} xs={12}>
              <MKTypography mb={1} variant="h4">
                Step 3: Configure Revenue Distribution
              </MKTypography>
              <MKTypography mb={1} variant="body2">
                For testing, 3 revenue splits are required. They have been preloaded with some data
                if needed. Feel free to replace any values. Future releases will support a more
                dynamic distribution model.
              </MKTypography>
            </Grid>

            <Grid item p={1} xs={12}>
              {formData.distributions !== undefined &&
                formData.distributions.length > 0 &&
                formData.distributions
                  .toSorted((b1, b2) => b1.id - b2.id)
                  .map((d) => (
                    <Grid key={d.id} container xs={12}>
                      <Grid item pb={1} pr={1} xs={4}>
                        <TextField
                          label="Name"
                          name={"distributionName" + d.id}
                          error={!!errorMsgs["distributionName" + d.id]}
                          helperText={errorMsgs["distributionName" + d.id]}
                          required
                          disabled={d.id === 0}
                          value={d.name}
                          onChange={(e) => {
                            handleUpdateDistribution(d.id, e.target.value, d.address, d.percentage);
                          }}
                          fullWidth
                          type="text"
                          color="primary"
                          size="small"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      <Grid item pb={1} pr={1} xs={5}>
                        <TextField
                          label="Address"
                          name={"distributionAddress" + d.id}
                          error={!!errorMsgs["distributionAddress" + d.id]}
                          helperText={errorMsgs["distributionAddress" + d.id]}
                          required
                          disabled={d.id === 0}
                          value={d.address}
                          onChange={(e) => {
                            handleUpdateDistribution(d.id, d.name, e.target.value, d.percentage);
                          }}
                          fullWidth
                          type="text"
                          color="primary"
                          size="small"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      <Grid item pb={1} pr={1} xs={2}>
                        <TextField
                          disabled={d.id === 0}
                          value={d.percentage}
                          error={!!errorMsgs["distributionAmount"]}
                          onChange={(e) => {
                            handleUpdateDistribution(d.id, d.name, d.address, e.target.value);
                          }}
                          fullWidth
                          type="number"
                          color="primary"
                          size="small"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      {formData.distributions.indexOf(d) != 0 && (
                        <Grid item pb={1} xs={1}>
                          <MKButton
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={() => {
                              handleRemoveRevenueSplit(d);
                            }}
                          >
                            X
                          </MKButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
              <MKBox xs={12} textAlign="right">
                <MKTypography
                  color={errorMsgs["distributionAmount"] ? "error" : "success"}
                  mb={1}
                  mr={2}
                >
                  {"Total: " +
                    formData.distributions.reduce(
                      (n, { percentage }) => parseInt(n) + parseInt(percentage),
                      0
                    )}
                </MKTypography>
                <MKButton
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    handleAddRevenueSplit();
                  }}
                >
                  Add Revenue Split
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
          <Grid item textAlign="center" xs={12}>
            <MKBox m={2}>
              <MKButton
                variant="contained"
                disabled={
                  !userSession.isUserSignedIn() ||
                  userSession.loadUserData().profile.stxAddress.testnet !=
                    "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR"
                }
                color="info"
                onClick={(e) => {
                  handlePreviewAgreement(e);
                }}
              >
                Validate and Deploy <br /> (Coming Soon)
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
        <Grid item textAlign="center" xs={12}>
          {statusMessage}
        </Grid>
      </Card>
      <Modal
        open={showPreview}
        onClose={handleClosePreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Event Preview
          </MKTypography>
          <Grid mt={3} container>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Name:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">{formData.eventName}</MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Description:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">{formData.eventDescription}</MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Start:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">
                {new Date(formData.startTime).toLocaleDateString() +
                  " " +
                  new Date(formData.startTime).toLocaleTimeString("en-US", startTimeOpt)}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                End:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">
                {new Date(formData.endTime).toLocaleDateString() +
                  " " +
                  new Date(formData.endTime).toLocaleTimeString("en-US", endTimeOpt)}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Capacity:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">{formData.ticketCount}</MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Price:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">{formData.ticketPrice + " STX"}</MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Contact:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">
                {formData.email ? formData.email : "[none supplied]"}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Phone:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">
                {formData.phone ? formData.phone : "[none supplied]"}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Transferable:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">{formData.isTransferable ? "Yes" : "No"}</MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Offers:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body2">{formData.offersAllowed ? "Yes" : "No"}</MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Event URL:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography
                component="a"
                target="_blank"
                href={formData.eventLink}
                variant="body2"
                fontWeight="light"
                color="info"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
                Test Link
              </MKTypography>
            </Grid>
            <Grid mt={3} item xs={4}>
              <MKTypography fontWeight="bold" variant="body2">
                Location:
              </MKTypography>
            </Grid>
            {formData.eventType === 6 && (
              <Grid mt={3} item xs={8}>
                <MKTypography fontWeight="bold" variant="body2">
                  {formData.locationName}
                </MKTypography>
                <MKTypography variant="body2">{formData.addressOne}</MKTypography>
                <MKTypography variant="body2">{formData.addressTwo}</MKTypography>
                <MKTypography variant="body2">
                  {formData.city +
                    " " +
                    formData.state +
                    ", " +
                    formData.postal +
                    " " +
                    formData.country}
                </MKTypography>
              </Grid>
            )}
          </Grid>
          <MKBox mt={2} textAlign="end">
            <MKButton
              variant="outlined"
              color="info"
              size="small"
              onClick={(e) => {
                handleClosePreview(e);
              }}
            >
              Cancel
            </MKButton>
            {"  "}
            <MKButton
              variant="contained"
              color="info"
              size="small"
              disabled={!validated || !userSession.isUserSignedIn()}
              onClick={(e) => {
                handleSendAgreement(e);
                handleClosePreview(e);
              }}
            >
              Deploy Event Contract
            </MKButton>
          </MKBox>
        </MKBox>
      </Modal>
      <Modal
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your new event has been deployed to the chain. Once it has been confirmed you will see
            your event listed in the Upcoming events. Let your followers know about the new event by
            clicking below to post to X!
          </MKTypography>
          <MKBox mt={1}>
            <TwitterShareButton
              title={
                formData.eventName +
                "\n" +
                new Date(formData.startTime).toLocaleDateString() +
                " " +
                new Date(formData.startTime).toLocaleTimeString("en-US", startTimeOpt) +
                " - " +
                new Date(formData.endTime).toLocaleTimeString("en-US", endTimeOpt) +
                "\n @risingtideproto \n \n"
              }
              url={"https://allaccess.rocks/allevents"}
              related={["risingtideproto"]}
              hashtags={["allaccess"]}
            >
              <XIcon size={32} />
            </TwitterShareButton>
          </MKBox>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CreateEvent;
