/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/LandingPages/RegistrationDetails/sections/Profile";
import SimpleFooter from "examples/Footers/SimpleFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import defaultHeader from "assets/images/city-profile.jpg";
import welshHeader from "assets/images/welsh/header.jpg";
import trajanHeader from "assets/images/trajan/header.jpg";
import nothingHeader from "assets/images/nothing/header.jpg";
import stackDaoHeader from "assets/images/stackingdao/header.jpg";
import { useGetPortalsByDestination } from "stores/PortalsStore";

const getDestinations = async (managerId) => {
  const events = [];

  await fetch(
    "https://rt-api-dev.vercel.app/api" +
      "/managers/" +
      managerId +
      "/destinations" +
      "?includeEventDetails=true&includeDistributions=true"
  )
    .then((data) => data.json())
    .then((json) => {
      const destinations = json;

      if (Array.isArray(destinations)) {
        for (
          let destinationCounter = 0;
          destinationCounter < destinations.length;
          destinationCounter++
        ) {
          const d = destinations[destinationCounter];
          const creatorInfo = {
            distributions: [],
          };

          if (d.distributions != undefined) {
            for (let distIdx = 0; distIdx < d.distributions.length; distIdx++) {
              const creator = {
                name: d.distributions[distIdx].name,
                address: d.distributions[distIdx].address,
                amount: d.distributions[distIdx].percentage,
              };

              creatorInfo.distributions.push(creator);
            }
          }

          const resultDest = {
            id: d.id,
            contractAddress: d.contract,
            name: d.destinationname,
            description: d.destinationdescription,
            identifier: d.assetidentifier,
            eventDetails:
              d.eventDetails != undefined
                ? {
                    name: d.eventDetails.name,
                    startTime: parseInt(d.eventDetails.starttime),
                    endTime: parseInt(d.eventDetails.endtime),
                    addressOne: d.eventDetails.addressone,
                    addressTwo: d.eventDetails.addresstwo,
                    city: d.eventDetails.city,
                    state: d.eventDetails.state,
                    postal: d.eventDetails.postal,
                    country: d.eventDetails.country,
                    contact: d.eventDetails.contact,
                  }
                : undefined,
            thumbnail: d.thumbnailuri,
            eventInfoUri: d.informationuri,
            minPurchaseFee: d.minopenfee,
            minLoadingFee: d.minopenfee,
            type: d.type,
            isTransferable: d.istransferable,
            availableSlots: d.maxportalsallowed,
            filledSlots: d.filledslots,
            creatorInfo: creatorInfo,
            logo: "/images/portal-active.svg",
            contractVersion: d.versionmajor + "." + d.versionminor + "." + d.versionpatch,
            offersAllowed: d.offersallowed,
            orderBy: d.orderby,
          };

          events.push(resultDest);
        }
      }
    });

  return events;
};

const useDestinationsQuery = (managerId) =>
  useQuery({
    queryKey: [managerId],
    queryFn: () => getDestinations(managerId),
  });

function RegistrationDetails() {
  let { eventId, resId } = useParams();

  const { data: destinations } = useDestinationsQuery(parseInt(2));

  const { data: portals } = useGetPortalsByDestination(2, eventId);

  const rsvp = portals != undefined && portals.find((r) => r.index == resId);

  const et = destinations != undefined && destinations.find((d) => d.id == eventId);

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${
                eventId == 1
                  ? welshHeader
                  : eventId == 2
                  ? nothingHeader
                  : eventId == 3
                  ? trajanHeader
                  : eventId == 4
                  ? stackDaoHeader
                  : defaultHeader
              })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -15,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile reservation={rsvp} event={et} />
        </Card>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default RegistrationDetails;
