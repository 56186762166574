/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useGetPortalsByAddress } from "stores/PortalsStore";

import { useQuery } from "react-query";
import { userSession } from "components/ConnectWallet";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-conference.jpg";

import SimpleFooter from "examples/Footers/SimpleFooter";
import EventRegistartionCard from "examples/Cards/EventCard/EventRegistrationCard";

const getDestinations = async (managerId) => {
  const events = [];

  await fetch(
    "https://rt-api-dev.vercel.app/api" +
      "/managers/" +
      managerId +
      "/destinations" +
      "?includeEventDetails=true&includeDistributions=true"
  )
    .then((data) => data.json())
    .then((json) => {
      const destinations = json;

      if (Array.isArray(destinations)) {
        for (
          let destinationCounter = 0;
          destinationCounter < destinations.length;
          destinationCounter++
        ) {
          const d = destinations[destinationCounter];
          const creatorInfo = {
            distributions: [],
          };

          if (d.distributions != undefined) {
            for (let distIdx = 0; distIdx < d.distributions.length; distIdx++) {
              const creator = {
                name: d.distributions[distIdx].name,
                address: d.distributions[distIdx].address,
                amount: d.distributions[distIdx].percentage,
              };

              creatorInfo.distributions.push(creator);
            }
          }

          const resultDest = {
            id: d.id,
            contractAddress: d.contract,
            name: d.destinationname,
            description: d.destinationdescription,
            identifier: d.assetidentifier,
            eventDetails:
              d.eventDetails != undefined
                ? {
                    name: d.eventDetails.name,
                    startTime: parseInt(d.eventDetails.starttime),
                    endTime: parseInt(d.eventDetails.endtime),
                    addressOne: d.eventDetails.addressone,
                    addressTwo: d.eventDetails.addresstwo,
                    city: d.eventDetails.city,
                    state: d.eventDetails.state,
                    postal: d.eventDetails.postal,
                    country: d.eventDetails.country,
                    contact: d.eventDetails.contact,
                  }
                : undefined,
            thumbnail: d.thumbnailuri,
            eventInfoUri: d.informationuri,
            minPurchaseFee: d.minopenfee,
            minLoadingFee: d.minopenfee,
            type: d.type,
            isTransferable: d.istransferable,
            availableSlots: d.maxportalsallowed,
            filledSlots: d.filledslots,
            creatorInfo: creatorInfo,
            logo: "/images/portal-active.svg",
            contractVersion: d.versionmajor + "." + d.versionminor + "." + d.versionpatch,
            offersAllowed: d.offersallowed,
            orderBy: d.orderby,
          };

          events.push(resultDest);
        }
      }
    });

  return events;
};

const useDestinationsQuery = (managerId) =>
  useQuery({
    queryKey: [managerId],
    queryFn: () => getDestinations(managerId),
  });

function MyProfile() {
  const { data: destinations } = useDestinationsQuery(parseInt(2));

  const { data: portals } = useGetPortalsByAddress(
    2,
    userSession !== undefined && userSession.isUserSignedIn()
      ? userSession.loadUserData().profile.stxAddress.testnet
      : ""
  );

  const portalStorage =
    destinations != undefined
      ? destinations.find(
          (d) =>
            d.contractAddress ==
            "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR.rt-ap-inactive-access-pass-00-00-001"
        )
      : undefined;

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              My Reservations{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              View and manage your All Access reservations. View offers, transfer passes and recycle
              access passes here.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -15,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          spacing={2}
          mt={2}
          mb={{ xs: 10, sm: 10, md: 10 }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid item textAlign="center" xs={12}>
            <MKTypography mb={3} textAlign="center" variant="h2">
              {portals != undefined && portals.length > 0
                ? "All Reservations"
                : userSession.isUserSignedIn()
                ? "No Reservations Found"
                : "Connect Wallet to View Profile"}
            </MKTypography>
            <MKTypography mb={1} textAlign="center" variant="body2">
              As new events are scheduled and deployed to the chain, the All Access API will make
              them available and you can find them here. Future releases will include custom
              searching and other utilities to enable you to quickly find events that matter to you
              most.
            </MKTypography>
          </Grid>
          {portals !== undefined &&
            portals
              .toSorted((b1, b2) => b2.index - b1.index)
              .map(
                (r) =>
                  portalStorage != undefined &&
                  r.destinationId != portalStorage.id && (
                    <Grid item key={r.id} xs={12} sm={12} md={6} lg={4} xl={3}>
                      <EventRegistartionCard
                        reservation={r}
                        event={destinations.find((d) => d.id == r.destinationId)}
                        image={destinations.find((d) => d.id == r.destinationId).thumbnail}
                        title={destinations.find((d) => d.id == r.destinationId).name}
                        action={{
                          type: "internal",
                          route: "/event/" + r.destinationId + "/registration/" + r.index,
                          color: "info",
                          label: "view reservation",
                        }}
                      />
                    </Grid>
                  )
              )}
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default MyProfile;
