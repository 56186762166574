// @mui material components
import Icon from "@mui/material/Icon";
import AllEvents from "pages/LandingPages/AllEvents";
import AvailablePasses from "pages/LandingPages/AvailablePasses";
import CreateEvent from "pages/LandingPages/CreateEvent";
import MyProfile from "pages/LandingPages/MyProfile";

const routes = [
  {
    name: "Events",
    icon: <Icon>event</Icon>,
    collapse: [
      {
        name: "Browse Events",
        description: "Search chain for events to attend. Activate Access Passes to reserve a spot.",
        icon: <Icon>event</Icon>,
        route: "/allevents",
        component: <AllEvents />,
      },
      {
        name: "Schedule Your Event",
        description:
          "Generate your event smart contract. Deploy it to the chain right from your wallet.",
        icon: <Icon>event</Icon>,
        route: "/createevent",
        component: <CreateEvent />,
      },
      {
        name: "Get Access Passes",
        description:
          "Purchase Access Passes. Access Passes are recylable and can be activated for multiple events.",
        icon: <Icon>event</Icon>,
        route: "/availablepasses",
        component: <AvailablePasses />,
      },
    ],
  },
  {
    name: "My Access Passes",
    icon: <Icon>local_activity</Icon>,
    route: "/availablepasses",
    component: <AvailablePasses />,
  },
  {
    name: "My Reservations",
    icon: <Icon>event_seat</Icon>,
    route: "/myreservations",
    component: <MyProfile />,
  },
];

export default routes;
