import { useQuery } from "react-query";

const getAddressInfo = async (addressList, checkWallet) => {
  const addressInfoList = [];

  for (let i = 0; i < addressList.length; i++) {
    const address = addressList[i];
    let addressInfo = {
      index: address.index,
      name: address.name,
      address: address.address,
    };

    if (checkWallet) {
      //look up player
      const headers = new Headers();
      headers.append("x-hiro-api-key", "f95f0bd797dae6a8decd1f402f795bc2");

      await fetch("https://api.hiro.so/extended/v1/address/" + address.address + "/balances", {
        headers: headers,
      })
        .then((data) => data.json())
        .then((json) => {
          addressInfo.hasInvaders =
            json.non_fungible_tokens[
              "SPV8C2N59MA417HYQNG6372GCV0SEQE01EV4Z1RQ.stacks-invaders-v0::stacks-invaders-v0"
            ] != undefined;

          addressInfo.hasRoo =
            json.fungible_tokens["SP2C1WREHGM75C7TGFAEJPFKTFTEGZKF6DFT6E2GE.kangaroo::kangaroo"] !=
            undefined;

          addressInfo.balance = json.stx.balance;
          addressInfo.stxBalance = json.stx.balance / 1000000;

          if (
            json.fungible_tokens[
              process.env.SBTC_ASSET !== undefined ? process.env.SBTC_ASSET : ""
            ] != undefined
          ) {
            addressInfo.sBtcBalance =
              json.fungible_tokens[
                process.env.SBTC_ASSET !== undefined ? process.env.SBTC_ASSET : ""
              ].balance;
          }
        });
    }
    addressInfoList.push(addressInfo);
  }

  console.log(addressInfoList);
  return addressInfoList;
};

export const useAddressListQuery = (addressList, checkWallet) =>
  useQuery({
    queryKey: [addressList, checkWallet],
    queryFn: () => getAddressInfo(addressList, checkWallet),
  });
