/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import {
  FungibleConditionCode,
  uintCV,
  principalCV,
  AnchorMode,
  PostConditionMode,
  stringAsciiCV,
  NonFungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
  makeStandardSTXPostCondition,
} from "@stacks/transactions";
import { useCallback } from "react";
import { StacksTestnet } from "@stacks/network";
import { openContractCall } from "@stacks/connect";
import { userSession } from "components/ConnectWallet";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import AddressViewer from "components/AddressViewer";
import TransactionViewer from "components/TransactionViewer";

// Images
function Profile({ reservation, event }) {
  const handleRecyclePass = (reservation, event) => {
    recyclePass(reservation, event);
  };

  const recyclePass = useCallback(async (reservation, event) => {
    // STX Transfer Config
    const stxSendCode = FungibleConditionCode.LessEqual;
    const stxSendAmount = BigInt(0);
    const stxSendAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const contractAddress = "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR";
    const contractName = "rt-ap-inactive-access-pass-00-00-001";

    // Portal Activated Config
    const portalActivatedAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const portalActivatedCode = NonFungibleConditionCode.Sends;
    const assetActivatedAddress = event.contractAddress.substring(
      0,
      event.contractAddress.indexOf(".")
    );
    const assetActivatedContractName = event.contractAddress.substring(
      event.contractAddress.indexOf(".") + 1,
      event.contractAddress.length
    );
    const assetActivatedName = event.identifier;
    const tokenActivatedAssetName = uintCV(reservation.index);
    const nonFungibleActivatedAssetInfo = createAssetInfo(
      assetActivatedAddress,
      assetActivatedContractName,
      assetActivatedName
    );

    // Portal Burn Config
    const portalBurnAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const portalBurnCode = NonFungibleConditionCode.DoesNotSend;

    const assetBurnAddress = "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR";
    const assetBurnContractName = "rt-ap-inactive-access-pass-00-00-001";
    const assetBurnName = "rt-ap-inactive-access-pass";
    const tokenBurnAssetName = uintCV(reservation.index);
    const nonFungibleBurnAssetInfo = createAssetInfo(
      assetBurnAddress,
      assetBurnContractName,
      assetBurnName
    );

    // STX Transfer Post Condition
    const transferStxCondition = makeStandardSTXPostCondition(
      stxSendAddress,
      stxSendCode,
      stxSendAmount
    );

    // Portal Activated Post Condition
    const portalActivatedPostCondition = makeStandardNonFungiblePostCondition(
      portalActivatedAddress,
      portalActivatedCode,
      nonFungibleActivatedAssetInfo,
      tokenActivatedAssetName
    );

    // Portal Burn Post Condition
    const portalBurnedPostCondition = makeStandardNonFungiblePostCondition(
      portalBurnAddress,
      portalBurnCode,
      nonFungibleBurnAssetInfo,
      tokenBurnAssetName
    );

    const network = new StacksTestnet();
    const options = {
      network: network,
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: "open-portal",
      functionArgs: [
        uintCV(reservation.index),
        uintCV(0),
        principalCV(userSession.loadUserData().profile.stxAddress.testnet),
        stringAsciiCV(""),
        stringAsciiCV(""),
        principalCV(event.contractAddress),
      ],
      postConditions: [
        transferStxCondition,
        portalActivatedPostCondition,
        portalBurnedPostCondition,
      ],
      anchorMode: AnchorMode.Any,
      postConditionMode: PostConditionMode.Deny,
      onFinish: (data) => {
        console.log(data);
      },
    };

    await openContractCall(options);
  }, []);

  return reservation != undefined && event != undefined ? (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }}>
            <MKAvatar src={event.thumbnail} alt={event.name} size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" mb={1}>
                <MKTypography variant="h3">{event.name}</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                {event.description}
                <MKBox>
                  <MKTypography
                    component="a"
                    href={"/event/" + event.id}
                    variant="body1"
                    fontWeight="light"
                    color="info"
                    my={1}
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        transform: `translateX(3px)`,
                        transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round": {
                        transform: `translateX(6px)`,
                      },
                    }}
                  >
                    Event Details <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                  <MKBox sx={{ border: 3, borderRadius: 5 }} color="text" p={3} mx={2} mt={4}>
                    <MKTypography variant="h4" mb={2}>
                      Ticket Stub
                    </MKTypography>
                    <MKTypography variant="body1" component="p" color="text">
                      ID: <b>{reservation.index}</b>
                    </MKTypography>
                    <MKTypography variant="body1" component="p" color="text">
                      Owner:
                      <>
                        <AddressViewer
                          address={reservation.owner != undefined ? reservation.owner : ""}
                          length="18"
                        />
                        <Icon color="info" sx={{ fontWeight: "bold" }}>
                          launch
                        </Icon>
                      </>
                    </MKTypography>
                    <MKTypography variant="body1" component="p" color="text">
                      Receipt:
                      {reservation != undefined && reservation.txid != undefined && (
                        <>
                          <TransactionViewer address={reservation.txid} length="12" />
                          <Icon color="info" sx={{ fontWeight: "bold" }}>
                            launch
                          </Icon>
                        </>
                      )}
                    </MKTypography>
                    <MKTypography variant="body1" component="p" color="text">
                      Status: <b>{reservation.status == 0 ? "Confirmed" : "Pending"}</b>
                    </MKTypography>
                    <MKTypography variant="body1" component="p" color="text">
                      Attendee: <b>{reservation.text1}</b>
                    </MKTypography>
                    <MKBox m={1}>
                      <MKTypography variant="body2" component="p">
                        <b>Notes: </b>
                        {event.type === 9 && reservation.text2 != undefined
                          ? reservation.text2.substring(0, 5) + "...******"
                          : reservation.text2}
                      </MKTypography>
                    </MKBox>
                    <MKBox textAlign="center" mt={2}>
                      <MKButton
                        target="_blank"
                        href={reservation.launchUri}
                        variant="outlined"
                        color="info"
                      >
                        {event.type <= 6
                          ? "Event Link"
                          : event.type === 7
                          ? "Meeting Link"
                          : "X Space Link"}
                        &nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>launch</Icon>
                      </MKButton>
                    </MKBox>
                  </MKBox>
                  {userSession.isUserSignedIn() &&
                    reservation.owner == userSession.loadUserData().profile.stxAddress.testnet && (
                      <MKBox textAlign="center" mt={8}>
                        <MKButton
                          variant="outlined"
                          color="error"
                          onClick={() => handleRecyclePass(reservation, event)}
                        >
                          Recycle Access Pass&nbsp;
                          <Icon sx={{ fontWeight: "bold" }}>recycling</Icon>
                        </MKButton>
                      </MKBox>
                    )}
                </MKBox>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  ) : (
    <></>
  );
}

// Typechecking props for the EventRegistartionCard
Profile.propTypes = {
  reservation: PropTypes.any,
  event: PropTypes.any,
};

export default Profile;
