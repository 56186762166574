/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

import { useGetPortalsByDestination } from "stores/PortalsStore";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";

function RandomWinner({ event }) {
  const [winnerSelected, setWinnerSelected] = useState(false);
  const { data: portals } = useGetPortalsByDestination(parseInt(2), parseInt(event.id), false);
  const winner = portals != undefined && portals[Math.floor(Math.random() * portals.length)];

  const handleRevealWinner = () => {
    setWinnerSelected(true);
  };

  return (
    <MKBox component="section" py={1}>
      <Grid container ml={1}>
        {winnerSelected ? (
          <>
            <Grid item xs={12}>
              <MKTypography variant="h4" mb={1}>
                {winner.text1}
              </MKTypography>
            </Grid>
            <Grid item ml={2} xs={12}>
              <MKTypography variant="h5" mb={1}>
                {winner.text2.substring(0, 8) + ".........."}
              </MKTypography>
            </Grid>
          </>
        ) : (
          <>
            <MKButton variant="outlined" color="info" onClick={() => handleRevealWinner()}>
              Click to Reveal Winner...
            </MKButton>
          </>
        )}
      </Grid>
    </MKBox>
  );
}

// Typechecking props for the DefaultEventCard
RandomWinner.propTypes = {
  event: PropTypes.any,
};

export default RandomWinner;
