/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

const startTimeOpt = { hour: "2-digit", minute: "2-digit" };
const endTimeOpt = { timeZoneName: "short", hour: "2-digit", minute: "2-digit" };

function EventRegistartionCard({ reservation, event, image, title, action }) {
  return (
    <Card>
      <MKBox textAlign="center" height="20rem" position="relative" borderRadius="lg" m={2}>
        <MKTypography
          display="block"
          height="4.3rem"
          variant="h4"
          textTransform="capitalize"
          fontWeight="regular"
        >
          {title}
        </MKTypography>
        <MKBox
          m={1}
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          height="6rem"
          position="relative"
          zIndex={1}
        />
        <MKTypography variant="caption" component="p" fontWeight="bold" color="text">
          {event.eventDetails !== undefined &&
            new Date(event.eventDetails.startTime).toLocaleDateString()}
          <br />
        </MKTypography>
        <MKTypography variant="caption" component="p" color="text">
          {event.eventDetails !== undefined &&
            new Date(event.eventDetails.startTime).toLocaleTimeString("en-US", startTimeOpt) +
              " - " +
              new Date(event.eventDetails.endTime).toLocaleTimeString("en-US", endTimeOpt)}
          <br />
        </MKTypography>
        <MKTypography variant="body1" mt={1} fontWeight="bold" component="p" color="text">
          {event.eventDetails.name}
        </MKTypography>
        {event != undefined && event.type == 6 && (
          <MKTypography variant="caption" component="p" color="text">
            {event.eventDetails !== undefined && event.eventDetails.addressOne}
            <br />
            {event.eventDetails !== undefined && event.eventDetails.addressTwo.length > 0 && (
              <div>
                {event.eventDetails !== undefined && event.eventDetails.addressTwo}
                <br />
              </div>
            )}
            {event.eventDetails !== undefined &&
              event.eventDetails.city +
                ", " +
                event.eventDetails.state +
                " " +
                event.eventDetails.postal +
                " " +
                event.eventDetails.country}
            <br />
          </MKTypography>
        )}
        {event != undefined && event.eventInfoUri && event.type === 7 && (
          <MKBox>
            <MKTypography
              component="a"
              href={event.eventInfoUri}
              target="_blank"
              variant="body2"
              fontWeight="light"
              color="info"
              sx={{
                width: "max-content",
                alignItems: "center",
              }}
            >
              Meeting Info <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </MKBox>
        )}
        {event != undefined &&
          event.eventInfoUri &&
          (event.type === 8 || event.type === 9 || event.type === 10) && (
            <MKBox>
              <MKTypography variant="body2" mt={1} fontWeight="bold" component="p">
                {event.eventDetails.contact}
              </MKTypography>
              <MKBox mt={-1}>
                <MKTypography
                  component="a"
                  href={event.eventInfoUri}
                  target="_blank"
                  variant="caption"
                  fontWeight="light"
                  color="info"
                  sx={{
                    width: "max-content",
                    alignItems: "center",
                  }}
                >
                  Set Reminder
                </MKTypography>
              </MKBox>
            </MKBox>
          )}
      </MKBox>
      <MKBox textAlign="center">
        <MKBox mt={-1}>
          <MKTypography variant="body2" color="text">
            Reservation for:
          </MKTypography>
          <MKTypography variant="body1" fontWeight="bold" color="text">
            {reservation.text1}
          </MKTypography>
          <MKTypography variant="caption" mt={2} component="p" color="text">
            Status: {reservation.status == 0 ? "Confirmed" : "Pending"}
          </MKTypography>
        </MKBox>
        <MKBox my={2}>
          {action.type === "external" ? (
            <MKButton
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              size="small"
              color={action.color ? action.color : "dark"}
            >
              {action.label}
            </MKButton>
          ) : (
            <MKButton
              component={Link}
              to={action.route}
              variant="gradient"
              size="small"
              color={action.color ? action.color : "dark"}
            >
              {action.label}
            </MKButton>
          )}
        </MKBox>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the EventRegistartionCard
EventRegistartionCard.propTypes = {
  reservation: PropTypes.any,
  event: PropTypes.any,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default EventRegistartionCard;
