/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import AddressViewer from "components/AddressViewer";

// Material Kit 2 React components

function Distributions({ event }) {
  return (
    <MKBox component="section" py={1}>
      <MKTypography variant="h5" mb={2}>
        Revenue Splits
      </MKTypography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {event.creatorInfo.distributions.map((d) => (
              <TableRow key={d.name}>
                <TableCell>
                  <MKTypography variant="caption">{d.name}</MKTypography>
                </TableCell>
                <TableCell>
                  <MKTypography variant="caption">
                    <AddressViewer address={d.address} length={12}></AddressViewer>
                  </MKTypography>
                </TableCell>
                <TableCell>
                  <MKTypography variant="caption">{d.amount + "%"}</MKTypography>
                </TableCell>
                <TableCell>
                  <MKTypography variant="caption">
                    {(event.minLoadingFee * (d.amount / 100)) / 1000000 + " STX"}
                  </MKTypography>
                </TableCell>
              </TableRow>
            ))}
            <TableRow key={"footer"}>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>
                <MKTypography variant="body2" color="info">
                  <b>{event.minLoadingFee / 1000000 + " STX"}</b>
                </MKTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MKBox>
  );
}

// Typechecking props for the DefaultEventCard
Distributions.propTypes = {
  event: PropTypes.any,
};

export default Distributions;
