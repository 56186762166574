/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import ActionableCounterCard from "examples/Cards/CounterCards/ActionableCounterCard";
import { useOverviewQuery } from "stores/OverviewStore";

function Counters() {
  const { data: overview } = useOverviewQuery(parseInt(2));

  return (
    <MKBox component="section" py={1}>
      {overview != undefined && (
        <Container>
          <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={4}>
              <ActionableCounterCard
                count={overview.totalEvents}
                suffix=""
                title="Events Scheduled"
                description="From concerts, to comedy shows... social meet ups to hackathons... we can support your event."
                action={{
                  type: "internal",
                  route: "/allevents",
                  label: "view events",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} display="flex">
              <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
              />
              <ActionableCounterCard
                count={overview.totalInactive}
                suffix=""
                title="Access Passes Waiting"
                description="Activate any access pass to gain entrance to any event. When the event ends, the ticket will stay in your wallet."
                action={{
                  type: "internal",
                  route: "/availablepasses",
                  label: "get access passes",
                }}
              />
              <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ActionableCounterCard
                count={overview.totalActive}
                suffix=""
                title="Active Reservations"
                description="Quickly and easily manage all your reservations from one place. Save the ticket as a keepsake, or recycle and use again."
                action={{
                  type: "internal",
                  route: "/myreservations",
                  label: "view reservations",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </MKBox>
  );
}

export default Counters;
