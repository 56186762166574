import { useQuery } from "react-query";

export const postPendingPortal = async (
  managerId,
  destinationId,
  index,
  owner,
  displayName,
  specialRequests
) => {
  const postOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      storageId: 6,
      destinationId: destinationId,
      index: index,
      owner: owner,
      text1: displayName,
      text2: specialRequests,
    }),
  };
  fetch(
    "https://rt-api-dev.vercel.app/api/managers/" +
      managerId +
      "/destinations/" +
      destinationId +
      "/portals/pending",
    postOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return undefined;
};

const getPortalsByDestination = async (managerId, destinationId) => {
  const portals = [];

  await fetch(
    "https://rt-api-dev.vercel.app/api/managers/" +
      managerId +
      "/destinations/" +
      destinationId +
      "/portals"
  )
    .then((data) => data.json())
    .then((json) => {
      const response = json;

      if (Array.isArray(response)) {
        for (let portalCounter = 0; portalCounter < response.length; portalCounter++) {
          const p = response[portalCounter];

          const resultPortal = {
            id: p.id,
            txid: p.txid,
            index: p.index,
            owner: p.owner,
            text1: p.text1,
            text2: p.text2,
            topBid: undefined,
            tokenUri: p.tokenuri,
            launchUri: p.launchuri,
            status: p.status,
            destinationId: p.destinationid,
          };

          portals.push(resultPortal);
        }
      }
    });

  return portals;
};

export const useGetPortalsByDestination = (managerId, destinationId, autoRefresh) =>
  useQuery({
    queryKey: [managerId, destinationId],
    queryFn: () => getPortalsByDestination(managerId, destinationId),
    enabled: destinationId > 0,
    refetchInterval: autoRefresh ? 4500 : false,
  });

const getPortalsByAddress = async (managerId, address) => {
  const portals = [];

  await fetch("https://rt-api-dev.vercel.app/api/managers/" + managerId + "/portals/" + address)
    .then((data) => data.json())
    .then((json) => {
      const response = json;

      if (Array.isArray(response)) {
        for (let portalCounter = 0; portalCounter < response.length; portalCounter++) {
          const p = response[portalCounter];

          const resultPortal = {
            id: p.id,
            txid: p.txid,
            index: p.index,
            owner: p.owner,
            text1: p.text1,
            text2: p.text2,
            topBid: undefined,
            tokenUri: p.tokenuri,
            launchUri: p.launchuri,
            status: p.status,
            destinationId: p.destinationid,
          };

          portals.push(resultPortal);
        }
      }
    });

  return portals;
};

export const useGetPortalsByAddress = (managerId, address) =>
  useQuery({
    queryKey: [managerId, address],
    queryFn: () => getPortalsByAddress(managerId, address),
    enabled: address != undefined && address.length > 0,
  });

const getPortalsByDestAndAddress = async (managerId, destinationId, address) => {
  const portals = [];

  await fetch(
    "https://rt-api-dev.vercel.app/api/managers/" +
      managerId +
      "/destinations/" +
      destinationId +
      "/portals/" +
      address
  )
    .then((data) => data.json())
    .then((json) => {
      const response = json;

      if (Array.isArray(response)) {
        for (let portalCounter = 0; portalCounter < response.length; portalCounter++) {
          const p = response[portalCounter];

          const resultPortal = {
            id: p.id,
            txid: p.txid,
            index: p.index,
            owner: p.owner,
            text1: p.text1,
            text2: p.text2,
            topBid: undefined,
            tokenUri: p.tokenuri,
            launchUri: p.launchuri,
            status: p.status,
            destinationId: p.destinationid,
          };

          portals.push(resultPortal);
        }
      }
    });

  return portals;
};

export const useGetPortalsByDestAndAddress = (managerId, destinationId, address) =>
  useQuery({
    queryKey: [managerId, address],
    queryFn: () => getPortalsByDestAndAddress(managerId, destinationId, address),
    refetchInterval: 4500,
    enabled: address != undefined && address.length > 0 && destinationId > 0,
  });
