export const postPendingChainhook = async (
  apiKey,
  contractDeployer,
  contractName,
  chainhookName,
  chainhook
) => {
  const postOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      apiKey: apiKey,
      contractDeployer: contractDeployer,
      contractName: contractName,
      chainhookName: chainhookName,
      chainhook: chainhook,
    }),
  };
  fetch("https://rt-api-dev.vercel.app/api/chainhooks/register", postOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return undefined;
};

export const postPendingTicketingAgreement = async (contractDeployer, contractName) => {
  const postOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      contractDeployer: contractDeployer,
      contractName: contractName,
    }),
  };
  fetch("https://rt-api-dev.vercel.app/api/contracts/register", postOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return undefined;
};
