/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import ComingSoon from "pages/LandingPages/ComingSoon";

function Information() {
  return (
    <MKBox component="section" py={2} my={2}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Its Your Event,
                    <br />
                    Why Let Them Control Your Tickets?
                  </>
                }
                description="With Clarity smart contracts, creating a ticketed event is as easy as creating a memecoin. And because you are the deployer, you can manage your own fees and revenue splits."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="Read more about how using Clarity traits in combination with Hiro Chainhooks allow for a fully onchain ticketing system."
                action={{
                  type: "internal",
                  route: "/comingsoon",
                  component: <ComingSoon />,
                  label: "Read the Docs",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="stadium"
                  title="Use for any event"
                  description="Once deployed to the chain all smart contracts are the same, so there isn't any difference between your neighborhood bbq and the major concert downtown. With just the cost of deploying a smart contract, you can use All Access to manage access or collect prepayments by simply deploying a smart contract. Think of the uses."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="local_activity"
                  title="Immutable Tickets"
                  description="Each ticket is built on top of the SIP-009 NFT standard. This means in addition to the All Access features and functionalitiy, each ticket can be both collected, traded or sold like any other digital asset. Because it will live onchain forever, the ticket can be used for future airdrops or exclusive access."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="STX + sBTC"
                  description="With the upcoming release of sBTC, you'll be able to accept payment in both STX and sBTC. Using the supported revenue splits, those payments can be instantly distributed to any wallet."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Events, available anywhere"
                  description="Because purchaing a ticket is as simple as submitting a transaction to the Stacks blockchain, tickets can be purchased anywhere. With the All Access API, events can be listed and searched anywhere."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
