/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useGetPortalsByDestination } from "stores/PortalsStore";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import { useAddressListQuery } from "stores/AddressStore";
import MKBox from "components/MKBox";

// Material Kit 2 React components

function GuestList({ event }) {
  const { data: portals } = useGetPortalsByDestination(parseInt(2), parseInt(event.id), true);

  const addressList = [];
  if (portals != undefined) {
    portals.map((p) => addressList.push({ index: p.index, name: p.text1, address: p.text2 }));
  }

  const { data: attendees } = useAddressListQuery(addressList, event.id == 55);

  return (
    <MKBox p={2} sx={{ overflowY: "auto", height: "400px" }}>
      <table>
        <tbody>
          {attendees != undefined &&
            attendees.map((a) => (
              <tr key={a.address}>
                <td>
                  <MKBox px={1}>
                    <MKTypography variant="body2">{attendees.indexOf(a) + 1}</MKTypography>
                  </MKBox>
                </td>
                <td>
                  <MKBox px={1}>
                    <a href={"/event/" + event.id + "/registration/" + a.index}>
                      <MKTypography variant="body2">{a.name}</MKTypography>
                    </a>
                  </MKBox>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </MKBox>
  );
}

// Typechecking props for the DefaultEventCard
GuestList.propTypes = {
  event: PropTypes.any,
};

export default GuestList;
