import { useQuery } from "react-query";

const getOverview = async (managerId) => {
  let overview = undefined;

  await fetch("https://rt-api-dev.vercel.app/api/managers/" + managerId + "/overview")
    .then((data) => data.json())
    .then((json) => {
      const summary = json;

      summary.totalEvents = summary.length;
      summary.totalInactive = parseInt(summary.find((event) => event.id === 6).count);
      summary.totalActive = 0;
      summary.map((event) => {
        if (event.id != 6) {
          summary.totalActive = parseInt(summary.totalActive) + parseInt(event.count);
        }
      });

      overview = summary;
    });

  console.log(overview);
  return overview;
};

export const useOverviewQuery = (managerId) =>
  useQuery({
    queryKey: ["overview", managerId],
    queryFn: () => getOverview(managerId),
  });
