/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { userSession } from "components/ConnectWallet";
import { openContractCall } from "@stacks/connect";
import { StacksTestnet } from "@stacks/network";
import {
  FungibleConditionCode,
  uintCV,
  principalCV,
  AnchorMode,
  PostConditionMode,
  makeStandardSTXPostCondition,
  NonFungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
} from "@stacks/transactions";
import { stringAscii } from "@stacks/transactions/dist/cl";
import { useCallback, useState } from "react";
import { useGetPortalsByDestAndAddress } from "stores/PortalsStore";
import { postPendingPortal } from "stores/PortalsStore";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

import { TwitterShareButton } from "react-share";
import { XIcon } from "react-share";

const startTimeOpt = { hour: "2-digit", minute: "2-digit" };
const endTimeOpt = { timeZoneName: "short", hour: "2-digit", minute: "2-digit" };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Activate({ event }) {
  //state vars
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [specialRequests, setSpecialRequests] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");

  const handleClose = () => setShowConfirmation(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.validity.valid) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const { data: portals } = useGetPortalsByDestAndAddress(
    2,
    6,
    userSession !== undefined && userSession.isUserSignedIn()
      ? userSession.loadUserData().profile.stxAddress.testnet
      : ""
  );

  const availablePortals =
    portals != undefined && portals.filter((p) => p.destinationId == 6 && p.status == 0);

  const handleLoadLauncher = (fee, blockId, name, description, selectedDestination) => {
    loadLauncher(fee, blockId, name, description, selectedDestination);
  };

  const loadLauncher = useCallback(async (fee, blockId, name, description, selectedDestination) => {
    // STX Transfer Config
    const stxSendCode = FungibleConditionCode.LessEqual;
    const stxSendAmount = BigInt(fee !== undefined ? fee : 10000000);
    const stxSendAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const contractAddress = selectedDestination.contractAddress.substring(
      0,
      selectedDestination.contractAddress.indexOf(".")
    );
    const contractName = selectedDestination.contractAddress.substring(
      selectedDestination.contractAddress.indexOf(".") + 1
    );

    // Portal Burn Config
    const portalActivatedAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const portalActivatedCode = NonFungibleConditionCode.Sends;
    const assetActivatedAddress = "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR";
    const assetActivatedContractName = "rt-ap-inactive-access-pass-00-00-001";
    const assetActivatedName = "rt-ap-inactive-access-pass";
    // const assetActivatedAddress =
    //   process.env.STORAGE_CONTRACT_ADDRESS !== undefined
    //     ? process.env.STORAGE_CONTRACT_ADDRESS
    //     : "";
    // const assetActivatedContractName =
    //   process.env.STORAGE_CONTRACT_NAME !== undefined ? process.env.STORAGE_CONTRACT_NAME : "";
    // const assetActivatedName =
    //   process.env.STORAGE_CONTRACT_IDENTIFIER !== undefined
    //     ? process.env.STORAGE_CONTRACT_IDENTIFIER
    //     : "";
    const tokenActivatedAssetName = uintCV(blockId);
    const nonFungibleActivatedAssetInfo = createAssetInfo(
      assetActivatedAddress,
      assetActivatedContractName,
      assetActivatedName
    );

    // Portal Activated Config
    const portalBurnAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const portalBurnCode = NonFungibleConditionCode.DoesNotSend;
    const assetBurnAddress = selectedDestination.contractAddress.substring(
      0,
      selectedDestination.contractAddress.indexOf(".")
    );
    const assetBurnContractName = selectedDestination.contractAddress.substring(
      selectedDestination.contractAddress.indexOf(".") + 1
    );
    const assetBurnName = selectedDestination.identifier;
    const tokenBurnAssetName = uintCV(blockId);
    const nonFungibleBurnAssetInfo = createAssetInfo(
      assetBurnAddress,
      assetBurnContractName,
      assetBurnName
    );

    // STX Transfer Post Condition
    const transferStxCondition = makeStandardSTXPostCondition(
      stxSendAddress,
      stxSendCode,
      stxSendAmount
    );

    // Portal Activated Post Condition
    const portalActivatedPostCondition = makeStandardNonFungiblePostCondition(
      portalActivatedAddress,
      portalActivatedCode,
      nonFungibleActivatedAssetInfo,
      tokenActivatedAssetName
    );

    // Portal Burn Post Condition
    const portalBurnedPostCondition = makeStandardNonFungiblePostCondition(
      portalBurnAddress,
      portalBurnCode,
      nonFungibleBurnAssetInfo,
      tokenBurnAssetName
    );

    const network = new StacksTestnet();
    const options = {
      network: network,
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: "open-portal",
      functionArgs: [
        uintCV(blockId),
        uintCV(fee !== undefined ? fee : 1000000),
        principalCV(userSession.loadUserData().profile.stxAddress.testnet),
        stringAscii(name),
        stringAscii(description),
        principalCV(
          "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR.rt-ap-inactive-access-pass-00-00-001"
        ),
      ],
      postConditions: [
        transferStxCondition,
        portalActivatedPostCondition,
        portalBurnedPostCondition,
      ],
      anchorMode: AnchorMode.Any,
      postConditionMode: PostConditionMode.Deny,
      onFinish: (data) => {
        postPendingPortal(
          parseInt(2),
          event.id,
          blockId,
          userSession.loadUserData().profile.stxAddress.testnet,
          name,
          description
        );
        setConfirmationTx(
          "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
        );
        setShowConfirmation(true);
        setName("");
        setSpecialRequests("");
      },
    };

    await openContractCall(options);
  }, []);

  return (
    <Card component="paper">
      <Grid container p={3} spacing={3}>
        <MKTypography variant="h5" ml={2} mb={-1}>
          {event.type === 9
            ? "Enter the giveaway..."
            : event.type === 10
            ? "Submit a question..."
            : "Reserve a spot!"}
        </MKTypography>
        <Grid item mt={1} xs={12}>
          <MKInput
            variant="outlined"
            inputProps={{ maxLength: 200 }}
            label={
              event.type === 6 ? "Display Name" : event.type === 7 ? "Display Name" : "X Handle"
            }
            fullWidth
            error={nameError}
            required
            value={name}
            onChange={(e) => {
              handleNameChange(e);
            }}
          />
        </Grid>
        <Grid item mt={1} xs={12}>
          <MKInput
            multiline
            fullWidth
            value={specialRequests}
            inputProps={{ maxLength: 200 }}
            rows={3}
            onChange={(e) => {
              setSpecialRequests(e.target.value);
            }}
            variant="outlined"
            label={
              event.type === 6
                ? "Additional Needs / Special Requests"
                : event.type === 7
                ? "Message to Host"
                : event.type === 9
                ? "Wallet address for prizes"
                : event.type === 10
                ? "Question to ask our guest"
                : "Message to Host"
            }
          />
        </Grid>
      </Grid>
      {availablePortals != undefined && availablePortals.length > 0 && (
        <Grid item textAlign="end" m={2} xs={12}>
          <MKButton
            variant="contained"
            disabled={!availablePortals || availablePortals.length < 1 || name.length <= 0}
            onClick={() =>
              handleLoadLauncher(
                availablePortals !== undefined && event !== undefined ? event.minLoadingFee : 0,
                portals != undefined
                  ? availablePortals.filter((p) => p.destinationId == 6)[0].index
                  : -1,
                name,
                specialRequests,
                event
              )
            }
            color="info"
          >
            Activate Pass (
            {availablePortals != undefined && availablePortals.length > 0
              ? availablePortals.length
              : 0}
            )
          </MKButton>
        </Grid>
      )}
      {(!availablePortals || availablePortals.length < 1) && (
        <Grid item textAlign="end" mr={2} xs={12}>
          <Grid item>
            <MKTypography component="a" href="/availablepasses" variant="body" color="info">
              Get an Access Pass&nbsp;&nbsp;
              <Icon>local_activity</Icon>
            </MKTypography>
          </Grid>
        </Grid>
      )}
      <br />
      <Modal
        open={showConfirmation}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your activation request transaction has been submitted. You will see this pass in a
            status of pending until the transaction has confirmed.
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Post to X to let others know so they can join in too!!
          </MKTypography>
          <MKBox mt={1}>
            <TwitterShareButton
              title={
                "I'm going to this all access event, you should too!! \n\n" +
                event.name +
                "\n" +
                new Date(event.eventDetails.startTime).toLocaleDateString() +
                " " +
                new Date(event.eventDetails.startTime).toLocaleTimeString("en-US", startTimeOpt) +
                " - " +
                new Date(event.eventDetails.endTime).toLocaleTimeString("en-US", endTimeOpt) +
                "\n @risingtideproto \n \n"
              }
              url={"https://allaccess.rocks/event/" + event.id}
              related={["risingtideproto"]}
              hashtags={["allaccess"]}
            >
              <XIcon size={32} />
            </TwitterShareButton>
          </MKBox>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
    </Card>
  );
}

// Typechecking props for the DefaultEventCard
Activate.propTypes = {
  event: PropTypes.any,
};

export default Activate;
