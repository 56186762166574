// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function AboutPasses() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <MKTypography variant="h3" my={1}>
              What&apos;s an Access Pass?
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              <b>Think of Access Passes as reusable tickets for events. </b>
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              <p>
                By purchasing an Access Pass, you take the first step in the ticketing process. When
                you have a blank Access Pass in your wallet, you can then activate that pass for
                entry into any upcoming event. When the event is over, you will either deactivate
                the pass for free to use it for another event, or keep the activated pass as a
                keepsake.
              </p>
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              <p>
                Consider keeping active passes in your wallet as they may be used for airdrops or
                upcoming promotion from the event hosts.
              </p>
            </MKTypography>
            <MKTypography
              component="a"
              href="/allevents"
              variant="body2"
              color="info"
              fontWeight="regular"
            >
              View Events to Activate Passes&nbsp;
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutPasses;
