/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";
import { useQuery } from "react-query";
import { userSession } from "components/ConnectWallet";
import { StacksTestnet } from "@stacks/network";
import { openContractCall } from "@stacks/connect";
import {
  FungibleConditionCode,
  uintCV,
  principalCV,
  AnchorMode,
  PostConditionMode,
  stringAsciiCV,
  makeStandardSTXPostCondition,
} from "@stacks/transactions";
import { useCallback, useState } from "react";
import { useGetPortalsByDestAndAddress } from "stores/PortalsStore";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-concert.jpg";

import SimpleFooter from "examples/Footers/SimpleFooter";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import AboutPasses from "./sections/AboutPasses";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const getDestinations = async (managerId) => {
  const events = [];

  await fetch(
    "https://rt-api-dev.vercel.app/api" +
      "/managers/" +
      managerId +
      "/destinations" +
      "?includeEventDetails=true&includeDistributions=true"
  )
    .then((data) => data.json())
    .then((json) => {
      const destinations = json;

      if (Array.isArray(destinations)) {
        for (
          let destinationCounter = 0;
          destinationCounter < destinations.length;
          destinationCounter++
        ) {
          const d = destinations[destinationCounter];
          const creatorInfo = {
            distributions: [],
          };

          if (d.distributions != undefined) {
            for (let distIdx = 0; distIdx < d.distributions.length; distIdx++) {
              const creator = {
                name: d.distributions[distIdx].name,
                address: d.distributions[distIdx].address,
                amount: d.distributions[distIdx].percentage,
              };

              creatorInfo.distributions.push(creator);
            }
          }

          const resultDest = {
            id: d.id,
            contractAddress: d.contract,
            name: d.destinationname,
            description: d.destinationdescription,
            identifier: d.assetidentifier,
            eventDetails:
              d.eventDetails != undefined
                ? {
                    name: d.eventDetails.name,
                    startTime: parseInt(d.eventDetails.starttime),
                    endTime: parseInt(d.eventDetails.endtime),
                    addressOne: d.eventDetails.addressone,
                    addressTwo: d.eventDetails.addresstwo,
                    city: d.eventDetails.city,
                    state: d.eventDetails.state,
                    postal: d.eventDetails.postal,
                    country: d.eventDetails.country,
                    contact: d.eventDetails.contact,
                  }
                : undefined,
            thumbnail: d.thumbnailuri,
            eventInfoUri: d.informationuri,
            minPurchaseFee: d.minopenfee,
            minLoadingFee: d.minopenfee,
            type: d.type,
            isTransferable: d.istransferable,
            availableSlots: d.maxportalsallowed,
            filledSlots: d.filledslots,
            creatorInfo: creatorInfo,
            logo: "/images/portal-active.svg",
            contractVersion: d.versionmajor + "." + d.versionminor + "." + d.versionpatch,
            offersAllowed: d.offersallowed,
            orderBy: d.orderby,
          };

          events.push(resultDest);
        }
      }
    });

  return events;
};

const useDestinationsQuery = (managerId) =>
  useQuery({
    queryKey: [managerId],
    queryFn: () => getDestinations(managerId),
  });

function AllEvents() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");

  const handleClose = () => setShowConfirmation(false);

  const handlePurchasePortal = (purchaseFunction, purchaseFee) => {
    purchasePortal(purchaseFunction, purchaseFee);
  };

  const purchasePortal = useCallback(async (purchaseFunction, purchaseFee) => {
    // With a contract principal
    const postConditionCode = FungibleConditionCode.LessEqual;
    const postConditionAmount = BigInt(purchaseFee);
    const postConditionAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const contractAddress = "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR";
    //   process.env.STORAGE_CONTRACT_ADDRESS !== undefined
    //     ? process.env.STORAGE_CONTRACT_ADDRESS
    //     : "";
    const contractName = "rt-ap-inactive-access-pass-00-00-001";
    //   process.env.STORAGE_CONTRACT_NAME !== undefined ? process.env.STORAGE_CONTRACT_NAME : "";

    // const contractAddress =
    //   process.env.STORAGE_CONTRACT_ADDRESS !== undefined
    //     ? process.env.STORAGE_CONTRACT_ADDRESS
    //     : "";
    // const contractName =
    //   process.env.STORAGE_CONTRACT_NAME !== undefined ? process.env.STORAGE_CONTRACT_NAME : "";

    const contractSTXPostCondition = makeStandardSTXPostCondition(
      postConditionAddress,
      postConditionCode,
      postConditionAmount
    );

    const network = new StacksTestnet();
    const options = {
      network: network,
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: purchaseFunction,
      functionArgs: [
        uintCV(purchaseFee),
        principalCV(userSession.loadUserData().profile.stxAddress.testnet),
        stringAsciiCV(""),
        stringAsciiCV(""),
      ],
      anchorMode: AnchorMode.Any,
      postConditions: [contractSTXPostCondition],
      postConditionMode: PostConditionMode.Deny,
      onFinish: (data) => {
        setConfirmationTx(
          "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
        );
        setShowConfirmation(true);
      },
    };

    await openContractCall(options);
  }, []);

  const { data: destinations } = useDestinationsQuery(parseInt(2));
  const { data: portals } = useGetPortalsByDestAndAddress(
    2,
    6,
    userSession !== undefined && userSession.isUserSignedIn()
      ? userSession.loadUserData().profile.stxAddress.testnet
      : ""
  );

  const storageInfo = destinations != undefined && destinations.find((d) => d.id == 6);

  const availablePortalCount =
    portals != undefined && portals.filter((p) => p.destinationId == 6 && p.status == 0).length;

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Access Passes
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Access Passes are activated for access to any event and can be saved or recycled.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          spacing={2}
          mb={{ xs: 10, sm: 10, md: 10 }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          p={2}
        >
          <Container>
            <Grid container xs={12} sx={{ mx: "auto" }}>
              <Grid item mt={4} xs={12} lg={6}>
                <MKBox>
                  <DefaultCounterCard
                    count={availablePortalCount}
                    suffix=""
                    title={
                      userSession.isUserSignedIn()
                        ? "Access Passes in Wallet"
                        : "Connect Wallet to View Available Access Passes"
                    }
                    description="Access Passes can be activated and used for any event. Reset them after the event to reuse them for free, or keep the ticket in your wallet as a keepsake."
                  />
                </MKBox>
              </Grid>
              <Grid item mt={5} xs={12} lg={6}>
                <MKBox container textAlign="center">
                  <MKTypography textAlign="center" variant="h5" mb={1}>
                    Purchase Additional Passes{" "}
                  </MKTypography>
                  <MKTypography textAlign="center" variant="body2" mb={3}>
                    Purchase a single pass or buy in bulk for additional savings{" "}
                  </MKTypography>
                  <MKBox m={1}>
                    <MKButton
                      variant="gradient"
                      disabled={!userSession.isUserSignedIn()}
                      color="info"
                      onClick={() => {
                        handlePurchasePortal("purchase-portal", storageInfo.minLoadingFee);
                      }}
                    >
                      <span>
                        Purchase 1 Pass
                        <br /> ({storageInfo.minLoadingFee / 1000000} STX)
                      </span>
                    </MKButton>
                  </MKBox>
                  <MKBox m={1} textAlign="center">
                    <MKButton
                      variant="gradient"
                      disabled={!userSession.isUserSignedIn()}
                      color="info"
                      onClick={() => {
                        handlePurchasePortal(
                          "purchase-portal-5",
                          0.95 * (5 * storageInfo.minLoadingFee)
                        );
                      }}
                    >
                      <span>
                        Purchase 5 Passes
                        <br /> ({5 * (storageInfo.minLoadingFee / 1000000) * 0.95} STX)
                      </span>
                    </MKButton>
                  </MKBox>
                  <MKBox m={1} textAlign="center">
                    <MKButton
                      variant="gradient"
                      disabled={!userSession.isUserSignedIn()}
                      color="info"
                      onClick={() => {
                        handlePurchasePortal(
                          "purchase-portal-10",
                          0.9 * (10 * storageInfo.minLoadingFee)
                        );
                      }}
                    >
                      <span>
                        Purchase 10 Passes
                        <br /> ({10 * (storageInfo.minLoadingFee / 1000000) * 0.9} STX)
                      </span>
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: { xs: 0, lg: -12 }, textAlign: { xs: "center", lg: "left" } }}
              >
                <AboutPasses />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Card>
      <Modal
        open={showConfirmation}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your Access Pass purchase request has been submitted. Access Passes will be available
            for you to use once they have confirmed onchain.
          </MKTypography>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AllEvents;
