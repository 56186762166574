import PropTypes from "prop-types";
import MKButton from "components/MKButton";

const TransactionViewer = ({ address, length }) => {
  return (
    <MKButton
      className="m-0 p-0"
      variant="link"
      size="sm"
      target="_blank"
      href={"https://explorer.hiro.so/txid/" + address + "?chain=testnet"}
    >
      {address.substring(0, Math.floor(length / 2)) +
        "..." +
        address.substring(address.length - Math.floor(length / 2), address.length)}
    </MKButton>
  );
};

// Typechecking props for the DefaultEventCard
TransactionViewer.propTypes = {
  address: PropTypes.string,
  length: PropTypes.number,
};

export default TransactionViewer;
