/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Information from "pages/Presentation/sections/Information";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-home.jpg";
import { userSession } from "components/ConnectWallet";
import Counters from "./sections/Counters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: { xs: 1, sm: 8 / 10, md: 7 / 10, lg: 5 / 10, xl: 4 / 10 },
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Presentation() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");
  const [faucetDisabled, setFaucetDisabled] = useState(false);
  const [faucetStatus, setFaucetStatus] = useState("");

  const handleCloseConfirmation = () => setShowConfirmation(false);

  const handleFaucet = async () => {
    if (userSession.isUserSignedIn()) {
      fetch(
        "https://api.testnet.hiro.so/extended/v1/faucets/stx?address=" +
          userSession.loadUserData().profile.stxAddress.testnet +
          "&&unanchored=true",
        {
          method: "POST",
        }
      )
        .then((response) => (response.ok && response.status != 429 ? response.json() : undefined))
        .then((data) => {
          if (data && data.success) {
            setConfirmationTx(
              "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
            );
            setShowConfirmation(true);
          } else {
            setFaucetStatus("Error Requesting STX from faucet. Try again later.");
            setFaucetDisabled(true);
          }
        })
        .catch(() => {
          setFaucetStatus("Error Requesting STX from faucet. Try again later.");
          setFaucetDisabled(true);
        });
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              All Access
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              The decentralized event scheduling and reservation protocol, powered Stacks and
              secured by Bitcoin.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox textAlign="center" mx={2}>
          <MKTypography variant="h2" fontWeight="bold" mb={0.5}>
            Thank you for your support!
          </MKTypography>
          <MKTypography variant="body2" mt={2}>
            Need a Stacks wallet?
            <br /> Download{" "}
            <a href="https://leather.io/install-extension" target="_blank" rel="noreferrer">
              Leather
            </a>{" "}
            extension here.
          </MKTypography>
          <MKBox mt={1} mb={3}>
            <MKButton
              disabled={faucetDisabled}
              variant="outlined"
              color="success"
              onClick={(e) => {
                handleFaucet(e);
              }}
            >
              Get Testnet STX
            </MKButton>
            <br />
            <MKTypography variant="caption" fontWeight="bold" mt={1} color="error">
              {faucetStatus}
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" color="text">
            All Access is a decentralized onchain event scheduling and reservation protocol.&nbsp;
            All Access is currently deployed to the Stacks blockchain and powered by Clarity smart
            contracts.
          </MKTypography>
          <MKTypography variant="body2" mt={2} color="text">
            Come in, purchase Access Passes, explore events, and participate in events... Need some
            extra guidance getting started? Check out our{" "}
            <a target="_blank" href="/guides/quickstart.pdf" color="info">
              quickstart guide
            </a>
            .
          </MKTypography>
        </MKBox>
        <Counters />
        <Information />
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Before exploring the upcoming events, be sure to pick up a few Access Passes. These Access Passes can be activated for any event. When the event is over, keep the activated pass as a keepsake or deactivate and use again!"
                action={{
                  type: "internal",
                  route: "/availablepasses",
                  label: "Purchase Access Passes",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="All Access API"
                description="Interested in using our API to list upcoming events on your own site? Click to learn more."
                action={{
                  type: "internal",
                  route: "/presentation",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Get Tickets from anywhere"
                description="Because purchasing a ticket is as simple as submiting a transaction to the Stacks blockchain, it can be done from anywhere. And with the upcoming sBTC release, sBTC can be used in addition to STX."
                action={{
                  type: "internal",
                  route: "/presentation",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We&apos;re exicted to make onchain tickets available at an event near you, soon!
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <Modal
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your request for Testnet STX has been submitted to the chain.
          </MKTypography>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
