/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useState } from "react";
import { userSession } from "components/ConnectWallet";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import Distributions from "./Distributions";
import EventInfo from "./EventInfo";
import Checkbox from "@mui/material/Checkbox";
import Activate from "./Activate";
import GuestList from "./GuestList";
import TextField from "@mui/material/TextField";
import { FormControlLabel } from "@mui/material";

import { TwitterShareButton } from "react-share";
import { XIcon } from "react-share";
import RandomWinner from "./RandomWinner";

import { StacksTestnet } from "@stacks/network";
import { openContractCall } from "@stacks/connect";
import {
  FungibleConditionCode,
  uintCV,
  AnchorMode,
  PostConditionMode,
  stringAsciiCV,
  makeStandardSTXPostCondition,
  boolCV,
} from "@stacks/transactions";

const startTimeOpt = { hour: "2-digit", minute: "2-digit" };
const endTimeOpt = { timeZoneName: "short", hour: "2-digit", minute: "2-digit" };

const guestlistStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const editStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Profile({ event }) {
  const [showEdit, setShowEdit] = useState(false);
  const [showAirdrop, setShowAirdrop] = useState(false);
  const [showGuestlist, setShowGuestlist] = useState(false);
  const [showWinner, setShowWinner] = useState(false);

  const [validated, setValidated] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState({});
  const [formData, setFormData] = useState({
    eventName: undefined,
    eventDescription: undefined,
    ticketCount: undefined,
    ticketPrice: undefined,
    thumbnailUri: undefined,
    isTransferable: undefined,
  });

  const handleEditClose = () => setShowEdit(false);
  const handleAirdropClose = () => setShowAirdrop(false);
  const handleWinnerClose = () => setShowWinner(false);
  const handleGuestlistClose = () => setShowGuestlist(false);

  const handleTextChanged = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    validateForm();
  };

  const handleCheckboxChanged = (event) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
    setValidated(false);
  };

  const handlePickWinner = () => {
    setShowWinner(true);
  };

  const handleGenerateAirdrop = () => {
    setShowAirdrop(true);
  };

  const handlePreviewUpdate = (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      console.log(errors);
    }
  };

  const handleSendUpdate = (e, formData) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      console.log(errors);
      setEventInfo(event, formData);
    }
  };

  const setEventInfo = useCallback(async (event, formData) => {
    // With a contract principal
    const postConditionCode = FungibleConditionCode.LessEqual;
    const postConditionAmount = BigInt(0);
    const postConditionAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const contractAddress = event.contractAddress.substring(0, event.contractAddress.indexOf("."));
    //   process.env.STORAGE_CONTRACT_ADDRESS !== undefined
    //     ? process.env.STORAGE_CONTRACT_ADDRESS
    //     : "";
    const contractName = event.contractAddress.substring(
      event.contractAddress.indexOf(".") + 1,
      event.contractAddress.length
    );
    //   process.env.STORAGE_CONTRACT_NAME !== undefined ? process.env.STORAGE_CONTRACT_NAME : "";

    // const contractAddress =
    //   process.env.STORAGE_CONTRACT_ADDRESS !== undefined
    //     ? process.env.STORAGE_CONTRACT_ADDRESS
    //     : "";
    // const contractName =
    //   process.env.STORAGE_CONTRACT_NAME !== undefined ? process.env.STORAGE_CONTRACT_NAME : "";

    const contractSTXPostCondition = makeStandardSTXPostCondition(
      postConditionAddress,
      postConditionCode,
      postConditionAmount
    );

    console.log(contractAddress);
    console.log(contractName);

    const network = new StacksTestnet();
    const options = {
      network: network,
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: "set-portal-info",
      functionArgs: [
        stringAsciiCV(formData.eventName),
        stringAsciiCV(formData.eventDescription),
        stringAsciiCV(formData.thumbnailUri),
        uintCV(formData.ticketPrice * 1000000),
        uintCV(formData.ticketCount),
        boolCV(formData.isTransferable),
      ],
      anchorMode: AnchorMode.Any,
      postConditions: [contractSTXPostCondition],
      postConditionMode: PostConditionMode.Deny,
      onFinish: (data) => {
        /* setConfirmationTx(
          "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
        );
        setShowConfirmation(true); */
        console.log(data);
      },
    };

    await openContractCall(options);
  }, []);

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    console.log(formData);

    if (!formData.eventName.trim()) {
      isValid = false;
      newErrors.eventName = "Event Name Required";
    }
    if (!formData.eventDescription.toString().trim()) {
      isValid = false;
      newErrors.eventDescription = "Event Description Required";
    }
    if (formData.ticketCount < 1) {
      isValid = false;
      newErrors.ticketCount = "Ticket Count Must Be Greater Than Zero";
    }
    if (formData.ticketPrice < 1) {
      isValid = false;
      newErrors.ticketPrice = "Ticket Price Must Be Greater Than Zero";
    }

    setErrorMsgs(newErrors);
    setValidated(isValid);
    return newErrors;
  };

  return event != undefined && event.eventDetails != undefined ? (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={event.thumbnail} alt={event.name} size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={1}>
            <Grid item mt={-6} xs={12} sm={12} md={12} lg={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <MKTypography variant="h3">
                  {event.name}{" "}
                  <TwitterShareButton
                    title={
                      "Get your tickets here for " +
                      event.name +
                      "\n" +
                      new Date(event.eventDetails.startTime).toLocaleDateString() +
                      " " +
                      new Date(event.eventDetails.startTime).toLocaleTimeString(
                        "en-US",
                        startTimeOpt
                      ) +
                      " - " +
                      new Date(event.eventDetails.endTime).toLocaleTimeString("en-US", endTimeOpt) +
                      "\n @risingtideproto \n \n"
                    }
                    url={"https://allaccess.rocks/event/" + event.id}
                    related={["risingtideproto"]}
                    hashtags={["allaccess"]}
                  >
                    <XIcon size={24} />
                  </TwitterShareButton>
                </MKTypography>
              </MKBox>
              <MKTypography variant="caption">
                {" "}
                {"Hosted By: " +
                  event.contractAddress.substring(0, event.contractAddress.indexOf("."))}
                <br />
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text">
                {event.description} <br />
              </MKTypography>
              <MKTypography
                component="a"
                target="_blank"
                href={"https://explorer.hiro.so/txid/" + event.contractAddress + "?chain=testnet"}
                variant="body1"
                fontWeight="light"
                color="info"
                my={1}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
                View event contract&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>link</Icon>
              </MKTypography>
              <Grid item mt={3} xs={12}>
                <Distributions event={event} />
              </Grid>
              <Grid item mt={3} xs={12}>
                <Activate event={event} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              mt={{ xs: 4, sm: 4, md: 4, lg: 1 }}
              mx={{ xs: "auto", sm: 6, md: 3 }}
            >
              <Grid container sx={{ ml: { xs: 0, sm: 0, md: 0, lg: 3, xl: 3 } }} ml={3}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <EventInfo event={event} />
                  </Grid>
                  <Grid item mb={4} xs={12}>
                    <MKBox mt={4}>
                      <MKTypography variant="h4">Event Information</MKTypography>
                      <MKBox mt={2}>
                        <MKTypography variant="h5">{event.eventDetails.name}</MKTypography>
                        {event.type == 6 && (
                          <MKTypography variant="body2">
                            {event.eventDetails !== undefined && event.eventDetails.addressOne}
                            <br />
                            {event.eventDetails !== undefined &&
                              event.eventDetails.addressTwo.length > 0 && (
                                <div>
                                  {event.eventDetails !== undefined &&
                                    event.eventDetails.addressTwo}
                                  <br />
                                </div>
                              )}
                            {event.eventDetails !== undefined &&
                              event.eventDetails.city +
                                ", " +
                                event.eventDetails.state +
                                " " +
                                event.eventDetails.postal +
                                " " +
                                event.eventDetails.country}
                            <br />
                          </MKTypography>
                        )}
                        <MKTypography variant="body2">{event.eventDetails.contact}</MKTypography>
                        {event != undefined && event.eventInfoUri && event.type === 7 && (
                          <MKBox>
                            <MKTypography
                              component="a"
                              href={event.eventInfoUri}
                              target="_blank"
                              variant="body1"
                              fontWeight="light"
                              color="info"
                              sx={{
                                width: "max-content",
                                display: "flex",
                                alignItems: "center",

                                "& .material-icons-round": {
                                  transform: `translateX(3px)`,
                                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                },

                                "&:hover .material-icons-round, &:focus .material-icons-round": {
                                  transform: `translateX(6px)`,
                                },
                              }}
                            >
                              Meeting Info <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                            </MKTypography>
                          </MKBox>
                        )}
                        {event != undefined && event.eventInfoUri && event.type === 8 && (
                          <MKBox mt={2}>
                            <MKTypography
                              component="a"
                              href={event.eventInfoUri}
                              target="_blank"
                              variant="body1"
                              fontWeight="light"
                              color="info"
                              sx={{
                                width: "max-content",
                                display: "flex",
                                alignItems: "center",

                                "& .material-icons-round": {
                                  transform: `translateX(3px)`,
                                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                },

                                "&:hover .material-icons-round, &:focus .material-icons-round": {
                                  transform: `translateX(6px)`,
                                },
                              }}
                            >
                              Set Reminder <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                            </MKTypography>
                          </MKBox>
                        )}
                        <MKTypography variant="h5" mt={3}>
                          {event.eventDetails !== undefined &&
                            new Date(event.eventDetails.startTime).toLocaleDateString()}
                          <br />
                        </MKTypography>
                        <MKTypography variant="body2">
                          {event.eventDetails !== undefined &&
                            new Date(event.eventDetails.startTime).toLocaleTimeString(
                              "en-US",
                              startTimeOpt
                            ) +
                              " - " +
                              new Date(event.eventDetails.endTime).toLocaleTimeString(
                                "en-US",
                                endTimeOpt
                              )}
                          <br />
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </Grid>
                  {userSession.isUserSignedIn() &&
                    userSession.loadUserData().profile.stxAddress.testnet != event.owner && (
                      <MKBox mt={1}>
                        <MKButton
                          onClick={() => {
                            setFormData({
                              eventName: event.name,
                              eventDescription: event.description,
                              ticketCount: event.availableSlots,
                              ticketPrice: event.minLoadingFee / 1000000,
                              thumbnailUri: event.thumbnail,
                              isTransferable: event.isTransferable,
                            });
                            setShowEdit(true);
                          }}
                          variant="outlined"
                          color="info"
                          size="small"
                        >
                          Edit Event
                        </MKButton>
                      </MKBox>
                    )}
                  <MKBox mt={1}>
                    <MKButton
                      onClick={() => setShowGuestlist(true)}
                      variant="outlined"
                      color="info"
                      size="small"
                    >
                      Attendee List ({event.filledSlots})
                    </MKButton>
                  </MKBox>
                  {userSession.isUserSignedIn() &&
                    userSession.loadUserData().profile.stxAddress.testnet != event.owner &&
                    event.type === 9 && (
                      <>
                        <MKBox mt={1}>
                          <MKButton
                            onClick={() => handlePickWinner()}
                            variant="outlined"
                            color="success"
                            size="small"
                          >
                            Pick Random Winner!
                          </MKButton>
                        </MKBox>
                        <MKBox mt={1}>
                          <MKButton
                            onClick={() => handleGenerateAirdrop()}
                            variant="outlined"
                            color="success"
                            size="small"
                          >
                            Generate Airdrop
                          </MKButton>
                        </MKBox>
                      </>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={showEdit}
        size="large"
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={editStyle}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Edit Event
          </MKTypography>
          <MKBox my={1}>
            <MKTypography id="modal-modal-description" variant="body2" component="p">
              Only certain details can be updated in your smart contract. Once deployed onchain,
              these details can only be changed by the original deployer, nobody else.
            </MKTypography>
          </MKBox>
          <Grid container>
            {/* Event Info */}
            <Grid item p={1} xs={12}>
              <MKBox m={1}>
                <TextField
                  label="Name"
                  name="eventName"
                  error={!!errorMsgs.eventName}
                  helperText={errorMsgs.eventName}
                  required
                  value={formData.eventName}
                  onChange={(e) => {
                    handleTextChanged(e);
                  }}
                  fullWidth
                  type="text"
                  color="primary"
                  size="small"
                ></TextField>
              </MKBox>
              <MKBox m={1}>
                <TextField
                  label="Description"
                  name="eventDescription"
                  error={!!errorMsgs.eventDescription}
                  helperText={errorMsgs.eventDescription}
                  required
                  value={formData.eventDescription}
                  onChange={(e) => {
                    handleTextChanged(e);
                  }}
                  fullWidth
                  multiline
                  rows={4}
                  type="text"
                  color="primary"
                  size="small"
                ></TextField>
              </MKBox>
              <MKBox m={2}>
                <TextField
                  label="Ticket Count"
                  name="ticketCount"
                  error={!!errorMsgs.ticketCount}
                  helperText={errorMsgs.ticketCount}
                  required
                  value={formData.ticketCount}
                  onChange={(e) => {
                    handleTextChanged(e);
                  }}
                  type="number"
                  color="primary"
                  size="small"
                  variant="outlined"
                ></TextField>
              </MKBox>
              <MKBox m={2}>
                <TextField
                  label="Ticket Price (STX)"
                  name="ticketPrice"
                  error={!!errorMsgs.ticketPrice}
                  helperText={errorMsgs.ticketPrice}
                  required
                  value={formData.ticketPrice}
                  onChange={(e) => {
                    handleTextChanged(e);
                  }}
                  type="number"
                  color="primary"
                  size="small"
                  variant="outlined"
                ></TextField>
              </MKBox>
              <MKBox m={2}>
                <TextField
                  label="Thumbnail Uri"
                  name="thumbnailUri"
                  error={!!errorMsgs.thumbnailUri}
                  helperText={errorMsgs.thumbnailUri}
                  value={formData.thumbnailUri}
                  onChange={(e) => {
                    handleTextChanged(e);
                  }}
                  fullWidth
                  type="text"
                  color="primary"
                  size="small"
                  variant="outlined"
                ></TextField>
              </MKBox>
              <MKBox m={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isTransferable"
                      checked={formData.isTransferable}
                      onChange={(e) => {
                        handleCheckboxChanged(e);
                      }}
                    />
                  }
                  label="Allow Transfers?"
                />
              </MKBox>
            </Grid>
            <Grid item textAlign="right" p={1} xs={12}>
              <MKBox m={1}>
                <MKButton
                  disabled
                  variant="outlined"
                  color="info"
                  onClick={(e) => {
                    handlePreviewUpdate(e);
                  }}
                >
                  Validate Event Data
                </MKButton>
                {"  "}
                <MKButton
                  variant="outlined"
                  disabled={
                    !userSession.isUserSignedIn() ||
                    userSession.loadUserData().profile.stxAddress.testnet !=
                      "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR" ||
                    !validated
                  }
                  color="success"
                  onClick={(e) => {
                    handleSendUpdate(e, formData);
                  }}
                >
                  Submit Update
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </Modal>
      <Modal
        open={showAirdrop}
        size="large"
        onClose={handleAirdropClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Make it Rain!!
          </MKTypography>
          <MKTypography id="modal-modal-description" variant="body1" component="p">
            Coming Soon...
          </MKTypography>
        </MKBox>
      </Modal>
      <Modal
        open={showWinner}
        size="large"
        onClose={handleWinnerClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h3">
            Congratulations!!
          </MKTypography>
          <MKBox m={3}>
            <RandomWinner event={event} />
          </MKBox>
        </MKBox>
      </Modal>
      <Modal
        open={showGuestlist}
        onClose={handleGuestlistClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={guestlistStyle}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Attendee List: {event.name}
          </MKTypography>
          <MKBox my={2}>
            <GuestList event={event} />
          </MKBox>
        </MKBox>
      </Modal>
    </MKBox>
  ) : (
    <div></div>
  );
}

// Typechecking props for the DefaultEventCard
Profile.propTypes = {
  event: PropTypes.any,
};

export default Profile;
