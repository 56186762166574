/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useQuery } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-allevents.jpg";

import SimpleFooter from "examples/Footers/SimpleFooter";
import DefaultEventCard from "examples/Cards/EventCard/DefaultEventCard";

const getDestinations = async (managerId) => {
  const events = [];

  await fetch(
    "https://rt-api-dev.vercel.app/api" +
      "/managers/" +
      managerId +
      "/destinations" +
      "?includeEventDetails=true&includeDistributions=true"
  )
    .then((data) => data.json())
    .then((json) => {
      const destinations = json;

      if (Array.isArray(destinations)) {
        for (
          let destinationCounter = 0;
          destinationCounter < destinations.length;
          destinationCounter++
        ) {
          const d = destinations[destinationCounter];
          const creatorInfo = {
            distributions: [],
          };

          if (d.distributions != undefined) {
            for (let distIdx = 0; distIdx < d.distributions.length; distIdx++) {
              const creator = {
                name: d.distributions[distIdx].name,
                address: d.distributions[distIdx].address,
                amount: d.distributions[distIdx].percentage,
              };

              creatorInfo.distributions.push(creator);
            }
          }

          const resultDest = {
            id: d.id,
            contractAddress: d.contract,
            name: d.destinationname,
            description: d.destinationdescription,
            identifier: d.assetidentifier,
            eventDetails:
              d.eventDetails != undefined
                ? {
                    name: d.eventDetails.name,
                    startTime: parseInt(d.eventDetails.starttime),
                    endTime: parseInt(d.eventDetails.endtime),
                    addressOne: d.eventDetails.addressone,
                    addressTwo: d.eventDetails.addresstwo,
                    city: d.eventDetails.city,
                    state: d.eventDetails.state,
                    postal: d.eventDetails.postal,
                    country: d.eventDetails.country,
                    contact: d.eventDetails.contact,
                  }
                : undefined,
            thumbnail: d.thumbnailuri,
            eventInfoUri: d.informationuri,
            minPurchaseFee: d.minopenfee,
            minLoadingFee: d.minopenfee,
            type: d.type,
            isTransferable: d.istransferable,
            availableSlots: d.maxportalsallowed,
            filledSlots: d.filledslots,
            creatorInfo: creatorInfo,
            logo: "/images/portal-active.svg",
            contractVersion: d.versionmajor + "." + d.versionminor + "." + d.versionpatch,
            offersAllowed: d.offersallowed,
            orderBy: d.orderby,
          };

          events.push(resultDest);
        }
      }
    });

  return events;
};

const useDestinationsQuery = (managerId) =>
  useQuery({
    queryKey: [managerId],
    queryFn: () => getDestinations(managerId),
    refetchInterval: 5000,
  });

function AllEvents() {
  const { data: destinations } = useDestinationsQuery(parseInt(2));

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Browse Events
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Browse through upcoming events. If you see one you like, activate any Access Pass for
              entrance.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -15,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          mt={{ xs: 4, sm: 4, md: 2 }}
          mb={{ xs: 10, sm: 10, md: 10 }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid item textAlign="center" xs={12}>
            <MKTypography mb={3} textAlign="center" variant="h2">
              Upcoming Events
            </MKTypography>
            <MKTypography mb={1} textAlign="center" variant="body2">
              As new events are scheduled and deployed to the chain, the All Access API will make
              them available and you can find them here. Future releases will include custom
              searching and other utilities to enable you to quickly find events that matter to you
              most.
            </MKTypography>
          </Grid>
          {destinations !== undefined &&
            destinations.length > 0 &&
            destinations
              .filter(
                (destination) =>
                  destination.eventDetails &&
                  destination.eventDetails.endTime > new Date().getTime()
              )
              .toSorted((b1, b2) => b1.eventDetails.startTime - b2.eventDetails.startTime)
              .map(
                (p) =>
                  p.contractAddress !=
                    "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR" +
                      "." +
                      "rt-ap-inactive-access-pass-00-00-001" && (
                    <Grid item textAlign="center" key={p.id} xs={12} sm={12} md={6} lg={4} xl={3}>
                      <MKBox p={1}>
                        <DefaultEventCard
                          event={p}
                          image={p.thumbnail}
                          title={p.name}
                          description={p.description}
                          action={{
                            type: "internal",
                            route: "/event/" + p.id,
                            color: "info",
                            label: "view event",
                          }}
                        />
                      </MKBox>
                    </Grid>
                  )
              )}
          <Grid item textAlign="center" xs={12}>
            <MKTypography mt={7} mb={3} textAlign="center" variant="h3">
              Past Events
            </MKTypography>
          </Grid>
          {destinations !== undefined &&
            destinations.length > 0 &&
            destinations
              .filter(
                (destination) =>
                  destination.eventDetails &&
                  destination.eventDetails.endTime < new Date().getTime()
              )
              .toSorted((b1, b2) => b2.eventDetails.startTime - b1.eventDetails.startTime)
              .map(
                (p) =>
                  p.contractAddress !=
                    "ST1CT7J2RWBZD62QAX36A2PQ3HKH5NFDGVJCFR2SR" +
                      "." +
                      "rt-ap-inactive-access-pass-00-00-001" && (
                    <Grid item textAlign="center" key={p.id} xs={12} sm={12} md={6} lg={4} xl={3}>
                      <MKBox p={1}>
                        <DefaultEventCard
                          event={p}
                          image={p.thumbnail}
                          title={p.name}
                          description={p.description}
                          action={{
                            type: "internal",
                            route: "/event/" + p.id,
                            color: "info",
                            label: "view event",
                          }}
                        />
                      </MKBox>
                    </Grid>
                  )
              )}
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AllEvents;
