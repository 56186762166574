/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

function EventInfo({ event }) {
  return (
    <>
      <MKTypography variant="h4" mb={1}>
        Ticketing Agreement
      </MKTypography>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <MKTypography variant="h5" mt={1} mb={1}>
            {event.type === 6
              ? "In Person Event"
              : event.type === 7
              ? "Online Event"
              : event.type === 8
              ? "X Space"
              : event.type === 9
              ? "Giveaway Space"
              : event.type === 10
              ? "AMA Space"
              : "Unknown"}
          </MKTypography>
          <FormGroup>
            <FormControlLabel
              disabled
              control={<Checkbox checked={event.isTransferable} />}
              label="Transferable"
            />
            <FormControlLabel
              disabled
              control={<Checkbox checked={event.offersAllowed} />}
              label="Offers Allowed"
            />
          </FormGroup>
          <MKTypography variant="body2" mt={1} mb={1}>
            {"Capacity: " + event.availableSlots}
          </MKTypography>
          <MKTypography variant="body2" mb={1}>
            {"Attending: " + event.filledSlots}
          </MKTypography>
          <MKTypography variant="body2" mb={1}>
            {"Remaining: " + (event.availableSlots - event.filledSlots)}
          </MKTypography>
          <MKTypography variant="h4" color="info">
            <b>{"" + event.minLoadingFee / 1000000 + " STX"}</b>
          </MKTypography>
        </Grid>
      </Grid>
    </>
  );
}

// Typechecking props for the DefaultEventCard
EventInfo.propTypes = {
  event: PropTypes.any,
};

export default EventInfo;
